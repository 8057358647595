import React, { useEffect, useRef, useState } from 'react';
import "./ReportSideBar.scss";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dummySmall from '../dummy_big.png';
import { CircularProgress } from '@mui/material';
import { getFetchReviewPostFileThumbnail } from '../../../redux/network/reportAPI';
import { getFetchGamePostFileThumbnail } from '../../../redux/network/videoApi';

export default function ReportSideBar() {
  const navigate = useNavigate();
  const { technique: paramsTech } = useParams();
  const location = useLocation();
  const pathName = location.pathname;

  const { isStarted, isError, error, TechniquesWithFocusArea } = useSelector(state => state.report.postsList);

  console.log("TechniquesWithFocusArea", TechniquesWithFocusArea)

  const reviewedPostData = JSON.parse(localStorage.getItem("reviewPostData"))
  const reviewPostId = reviewedPostData?._id;

  const jwtToken = localStorage.getItem("BQS_TOKEN");

  const [isUploadedVideoDisplay, setIsUploadedVideoDisplay] = useState(false);
  const [areOptionsDisplay, setAreOptionsDisplay] = useState([]);
  const [thumbnails, setThumbnails] = useState({});
  const [loadingThumbnails, setLoadingThumbnails] = useState({});

  const [uploadedVideoThumbnail, setUploadedVideoThumbnail] = useState(null)

  const imageContainerRefs = useRef({});
  const scrollDirections = useRef({});

  const scrollAmount = 100;
  const intervalTime = 3000;

  const handleSelectApproach = (technique, approach) => {
    const urlMap = {
      yourApproach: `/reports/${reviewPostId}/${technique}/your-approach`,
      correctApproach: `/reports/${reviewPostId}/${technique}/correct-approach`
    };
    navigate(urlMap[approach]);
  };

  const handleUploadedVideo = () => {
    navigate(`/reports/${reviewPostId}`);
  };

  const handleSetAreOptionDisplay = (optionName) => {
    if (areOptionsDisplay.includes(optionName)) {
      setAreOptionsDisplay(areOptionsDisplay.filter(option => option !== optionName));
    } else {
      setAreOptionsDisplay([...areOptionsDisplay, optionName]);
    }
  };

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, reviewedPostData?.game?.tnPath);
        setUploadedVideoThumbnail(thumbnailUrl);
      } catch (error) {
        console.error('Error fetching thumbnail:', error);
      }
    };

    fetchThumbnail();
  }, [jwtToken, reviewPostId, reviewedPostData?.game?.tnPath]);

  useEffect(() => {
    const fetchThumbnails = async () => {
      for (const focusArea of Object.keys(TechniquesWithFocusArea)) {
        for (const technique of TechniquesWithFocusArea[focusArea]) {
          const techName = Object.keys(technique)[0];
          let techThumbnails = {};
          let yourApproachThumbnails = [];
          let correctApproachThumbnails = [];

          // Set loading state to true
          setLoadingThumbnails(prev => ({ ...prev, [`${focusArea}${techName}`]: true }));

          for (const item of technique[techName]) {
            {/* YOUR APPROACH THUMBNAILS FETCH */ }
            if (item.clips && Array.isArray(item.clips)) {
              for (const video of item.clips) {
                try {
                  const thumbnailUrl = await getFetchReviewPostFileThumbnail(jwtToken, reviewPostId, video.tnFileName);
                  yourApproachThumbnails.push(thumbnailUrl);
                } catch (error) {
                  // Handle error
                }
              }
            }

            {/* CORRECT APPROACH THUMBNAILS FETCH */ }
            if (item.correct_approach_video && Array.isArray(item.correct_approach_video)) {
              for (const video of item.correct_approach_video) {
                try {
                  const thumbnailUrl = await getFetchReviewPostFileThumbnail(jwtToken, reviewPostId, video.tnFileName);
                  correctApproachThumbnails.push(thumbnailUrl);
                } catch (error) {
                  // Handle error
                }
              }
            }

          }

          techThumbnails = {
            yourApproach: yourApproachThumbnails,
            correctApproach: correctApproachThumbnails,
          };

          // Update thumbnails and set loading state to false
          setThumbnails(prev => ({ ...prev, [`${focusArea}${techName}`]: techThumbnails }));
          setLoadingThumbnails(prev => ({ ...prev, [`${focusArea}${techName}`]: false }));
        }
      }
    };

    if (TechniquesWithFocusArea) {
      fetchThumbnails();
    }
  }, [TechniquesWithFocusArea, jwtToken, reviewPostId]);

  useEffect(() => {
    const intervals = [];

    Object.keys(imageContainerRefs.current).forEach(key => {
      scrollDirections.current[key] = 1;
      const container = imageContainerRefs.current[key];
      const interval = setInterval(() => {
        if (container) {
          const maxScrollLeft = container.scrollWidth - container.clientWidth;
          if (container.scrollLeft + scrollAmount * scrollDirections.current[key] > maxScrollLeft || container.scrollLeft + scrollAmount * scrollDirections.current[key] < 0) {
            scrollDirections.current[key] *= -1;
          }
          container.scrollBy({
            left: scrollAmount * scrollDirections.current[key],
            behavior: 'smooth'
          });
        }
      }, intervalTime);
      intervals.push(interval);
    });

    return () => intervals.forEach(interval => clearInterval(interval));
  }, [thumbnails, scrollAmount, intervalTime]);

  return (
    <div>
      <section className='ReportSideBar'>
        <div className="report_side_nav">
          <div className="head">
            <h3>Contents</h3>
          </div>
          {isStarted ? (
            <div className='loading_container'>
              <CircularProgress color="inherit" size={10} />
              <span>Please Wait...</span>
            </div>
          ) : isError ? (
            <span>{error}</span>
          ) : (
            <div className="video_cont">
              <div className={`uploaded_video`} onClick={handleUploadedVideo}>
                <div className='uploadVideoHeader'>
                  <h6>Uploaded Video</h6>
                  {
                    isUploadedVideoDisplay ? (
                      <i className="fa-solid fa-sort-down" style={{ color: "white" }} onClick={() => setIsUploadedVideoDisplay(!isUploadedVideoDisplay)}></i>
                    ) : (
                      <i className="fa-solid fa-sort-up" onClick={() => setIsUploadedVideoDisplay(!isUploadedVideoDisplay)} style={{ color: "white" }}></i>
                    )
                  }
                </div>
                <img src={uploadedVideoThumbnail || dummySmall} alt="" className={`uploadeVideoThumbnailImg ${pathName === `/reports/${reviewPostId}` ? isUploadedVideoDisplay ? "active_uploaded_video displayNone" : "active_uploaded_video" : ""}`} />
              </div>
              {Object.entries(TechniquesWithFocusArea).map(([focusArea, techniques], index) => (
                techniques.map((technique, idx) => {
                  const techName = Object.keys(technique)[0];

                  return (
                    <div className="option" key={`${focusArea}-${idx}`}>
                      <div className='techniqueHeader'>
                        <h6>{focusArea}  {techName}</h6>
                        {
                          areOptionsDisplay.includes(focusArea + techName) ? (
                            <i className="fa-solid fa-sort-down" style={{ color: "white" }} onClick={() => handleSetAreOptionDisplay(focusArea + techName)}></i>
                          ) : (
                            <i className="fa-solid fa-sort-up" style={{ color: "white", cursor: "pointer" }} onClick={() => handleSetAreOptionDisplay(focusArea + techName)}></i>
                          )
                        }
                      </div>
                      <div className={`videoBox ${areOptionsDisplay.includes(focusArea + techName) ? "displayNone" : ""}`}>
                        {/* YOUR APPROACH */}
                        {thumbnails[focusArea + techName]?.yourApproach.length > 0 && (
                          <div className='approach_container'>
                            <h3>Your Approach</h3>
                            {loadingThumbnails[`${focusArea}${techName}`] ? (
                              <div className='loading_container'>
                                <CircularProgress color="inherit" size={20} />
                              </div>
                            ) : (
                              <div className={`image_container ${pathName.split("/").includes("your-approach") && techName === paramsTech ? "selected" : ""}`} onClick={() => handleSelectApproach(techName, "yourApproach")}>
                                <div className='slide_panel' ref={el => (imageContainerRefs.current[`${focusArea}${techName}yourApproach`] = el)}>
                                  {thumbnails[focusArea + techName]?.yourApproach?.map((eachThumbnailUrl, index) =>
                                    <img key={index} src={eachThumbnailUrl} className='slider_image' />
                                  )}
                                </div>
                                <span>{thumbnails[focusArea + techName]?.yourApproach.length}</span>
                              </div>
                            )}
                          </div>
                        )}

                        {/* CORRECT APPROACH */}
                        {thumbnails[focusArea + techName]?.correctApproach.length > 0 && (
                          <div className='approach_container'>
                            <h3>Correct Approach</h3>
                            {loadingThumbnails[`${focusArea}${techName}`] ? (
                              <div className='loading_container'>
                                <CircularProgress color="inherit" size={20} />
                              </div>
                            ) : (
                              <div className={`image_container ${pathName.split("/").includes("correct-approach") && techName === paramsTech ? "selected" : ""}`} onClick={() => handleSelectApproach(techName, "correctApproach")}>
                                <div className='slide_panel' ref={el => (imageContainerRefs.current[`${focusArea}${techName}correctApproach`] = el)}>
                                  {thumbnails[focusArea + techName]?.correctApproach?.map((eachThumbnailUrl, index) =>
                                    <img key={index} src={eachThumbnailUrl} className='slider_image' />
                                  )}
                                </div>
                                <span>{thumbnails[focusArea + techName]?.correctApproach.length}</span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              ))}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
