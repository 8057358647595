import React, { useEffect } from 'react';
import ReportSideBar from '../ReportSideBar/ReportSideBar';
import "./ReportOutlet.scss";
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getfetchreviewpost } from '../../../redux/network/reportAPI';
import { getFetchPostContent } from '../../../redux/network/videoApi';


export default function ReportOutlet() {
  const dispatch = useDispatch();
  const reviewedPostData = JSON.parse(localStorage.getItem('reviewPostData'));
  const reviewPostId = reviewedPostData?._id;

  const jwtToken = localStorage.getItem("BQS_TOKEN");

  const { value: postList, techniques } = useSelector(state => state.report.postsList);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getfetchreviewpost(jwtToken, reviewPostId))
    dispatch(getFetchPostContent(jwtToken, reviewPostId))
  }, [])

  console.log("techniques", techniques)

  return (
    <>
      <div className='ReportOutlet'>
        <ReportSideBar />
        <div className='content'>
          <Outlet />
        </div>
      </div>
    </>

  );
}
