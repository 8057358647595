import './App.scss';
import Routers from "./Routes/Routes"
import { useEffect } from 'react';
import { refreshToken, userLogout } from './redux/network/userApi';
import { setAlertMessage, setAlertType } from './redux/reducers/alertSlice';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch()
  const jwtToken = localStorage.getItem("BQS_TOKEN");

  // const handleRefreshToken = () => {
  //   refreshToken().then(response => {
  //     console.log("Success:", response);
  //     return response.json()
  //   }).then(response => {
  //     console.log("Success:", response);
  //     if (response && 'code' in response && response['code'] == 200) {
  //       dispatch(setAlertType("success"));
  //       dispatch(setAlertMessage("Token refreshed successfully."));
  //       const newToken = response.data.token.token;
  //       console.log("New token:", newToken);
  //       localStorage.setItem('BQS_TOKEN', newToken);
  //     } else {
  //       //Logout user if token not get refresh
  //       userLogout().then(res => {
  //         console.log(res)
  //         return res.json();
  //       }).then(response => {
  //         console.log("LOGOUT RESPONSE: ", response);
  //         if (response && 'code' in response && response['code'] == 200) {
  //           dispatch(setAlertType("success"));
  //           dispatch(setAlertMessage("You have logged out successfully."));
  //           localStorage.clear()

  //         } else {
  //           localStorage.clear()
  //           dispatch(setAlertType("error"));
  //           dispatch(setAlertMessage(response.message || "Oops! Something went wrong.Please try again later"));
  //         }
  //       }).catch(error => {
  //         dispatch(setAlertType("error"));
  //         dispatch(setAlertMessage("Oops! Something went wrong.Please try again later"));

  //       })
  //     }
  //   })
  //     .catch(error => {
  //       console.error("Error:", error);
  //     });
  // };

  // //refresh token after 8 min
  // useEffect(() => {
  //   let refreshedIntervalId;
  //   if (jwtToken) {
  //     refreshedIntervalId = setInterval(handleRefreshToken, 20000);
  //   } else {
  //     clearInterval(refreshedIntervalId);
  //   }
  //   return () => clearInterval(refreshedIntervalId);
  // }, [jwtToken]);

  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;
