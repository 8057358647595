import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from "./Footer.module.scss"

export default function Footer() {
    const location = useLocation()
    const isReportsPath = location.pathname.startsWith('/reports/');



    const [currentYear, setCurrentYear] = useState('');

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const year = new Date().getFullYear();
        setCurrentYear(year);
    }, []);

    if (isReportsPath) {
        return;
    }

    return (
        <footer>
            <div className='container'>
                <div className={styles.wrp}>

                    <div className={styles.box}>
                        <h6>company</h6>
                        <ul>
                            <li><Link to="/blogs">Blog</Link></li>
                            <li><Link to="/about">About us</Link></li>
                        </ul>
                    </div>
                    <div className={styles.box}>
                        <h6>Contact US</h6>
                        <ul>
                            <li><a href="mailto:info@visist.ai">info@visist.ai</a></li>
                            <li><a href='https://maps.app.goo.gl/nHB3ehxGGAPM3T747' target='_blank'>62/2-1, 8MAIN, 132 EMBASSY HERITAGE, MALLESWARAM, BENGALURU 560055 INDIA</a></li>

                        </ul>
                    </div>
                    <div className={styles.box}>
                        <h6>Legal</h6>
                        <ul>
                            <li><Link to='/term_service'>Terms of Service</Link></li>
                            <li><Link to='/privacy_policy'>Privacy Policy</Link></li>

                        </ul>
                    </div>
                    <div className={styles.box}>
                        <button onClick={scrollToTop}>Back to top <span><i className="fa-solid fa-arrow-up-long"></i></span></button>
                    </div>
                </div>
                <div className={styles.footer_btm}>
                    <div id='border'></div>
                    <p className={styles.company_name}>VISIST AI TECHNOLOGIES PRIVATE LIMITED &copy; {currentYear}</p>
                    <div id='border'></div>
                </div>
            </div>
        </footer>
    )
}
