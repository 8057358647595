import React, { useCallback, useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-markers/dist/videojs.markers.css";
import "videojs-markers";
import { Stage, Layer, Rect } from "react-konva";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeVideoPostStatus,
  createUpdateFeedback,
  getCoachFeedback,
} from "../../redux/network/coachAPI";
import { addStandaloneFeedback } from "../../redux/reducers/standaloneSlice";
import { updateStandAloneVideoStatusFunction } from "../../redux/network/standAloneApi";
import styles from "./Feedback.module.scss";
import { GET_POST_CONTENT } from "../../config/endpoints";
import {
  error_GetFetchVideoContent,
  isStarted_GetFetchVideoContent,
  success_GetFetchVideoContent,
} from "../../redux/reducers/videoSlice";
import { getFetchGamePostFileThumbnail } from "../../redux/network/videoApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import { FaMicrophone, FaTrash as FaTrashIcon } from "react-icons/fa";
import { FFmpeg } from "@ffmpeg/ffmpeg";
const Feedback = () => {
  const { postId: videoId } = useParams();
  const env = process.env.REACT_APP_ENV;
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [comment, setComment] = useState("");
  const [newAnnotation, setNewAnnotation] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [drawing, setDrawing] = useState(false);
  const navigate = useNavigate();
  const videoContent = useSelector((state) => state.video.videoContent);
  const dispatch = useDispatch();
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const standAloneFeedbacks = useSelector(
    (state) => state.standAlone.feedbacks.feedbackData
  );
  const [annotations, setAnnotations] = useState([]);
  const [annotations1, setAnnotations1] = useState([]);
  const [audioComment, setAudioComment] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const maxRecordingDuration =
    parseInt(process.env.REACT_APP_MAX_RECORDING_DURATION) || 4; // Default to 4 seconds if not set
  const ffmpeg = new FFmpeg();
  const maxAudioFeedbacks = parseInt(process.env.REACT_APP_MAX_AUDIO_FEEDBACKS) || 2; // Default to 2 if not set
  const [mediaStream, setMediaStream] = useState(null); // Add this new state

  console.log("videoDimensions==========", videoDimensions);
  const [expandedCommentId, setExpandedCommentId] = useState(null); // Track the expanded comment ID
  const controlBarHeight = 25;
  const userId = localStorage.getItem("BQS__ID");
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const firstname = localStorage.getItem("firstname");
  const lastname = localStorage.getItem("lastname");
  const reviewPost = JSON.parse(localStorage.getItem("reviewPost"));
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isStarted, isError, error, data } = useSelector(
    (state) => state.video.video
  );
  const [frameRate, setFrameRate] = useState(30);
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const thumbnailUrl =
    videoThumbnails[videoId] ||
    "https://www.fisu.net/app/uploads/2023/09/badminton.jpg";
  const [videoKey, setVideoKey] = useState(Date.now()); // Force re-render video element
  const [isLoading, setIsLoading] = useState(false); // State for the loader
  const konvaRef = useRef(null);
  // console.log("frameRate===", frameRate);
  const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [annotationToDelete, setAnnotationToDelete] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // Add this new state
  const [playingAudioId, setPlayingAudioId] = useState(null);

  useEffect(() => {
    if (data?.length > 0 && jwtToken && env !== "standalone") {
      const fetchThumbnail = async (reviewPostId, tnPath) => {
        try {
          const thumbnailUrl = await getFetchGamePostFileThumbnail(
            jwtToken,
            reviewPostId,
            tnPath
          );
          setVideoThumbnails((prev) => ({
            ...prev,
            [reviewPostId]: thumbnailUrl,
          }));
        } catch (error) {
          console.error("Error fetching thumbnail:", error);
        }
      };
      data?.forEach((item) => {
        fetchThumbnail(item._id, item.game[0]?.tnPath);
      });
    }
  }, [data, jwtToken, userId, env]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const url = `${GET_POST_CONTENT}?postId=${videoId}&type=game`;
        const url = localStorage.getItem("videoLinkInObjectStore");
        dispatch(isStarted_GetFetchVideoContent());

        const response = await fetch(url, {
          method: "GET",
          // headers: {
          //   'Authorization': `Bearer ${jwtToken}`,
          // },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch video");
        }

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });

        const responseBlob = await new Response(stream).blob();
        const file = new File([responseBlob], "video.mp4", {
          type: "video/mp4",
        });
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          const res = fr.result;
          dispatch(success_GetFetchVideoContent(res));
          setVideoLoaded(true);
          setVideoKey(Date.now()); // Mark the video as loaded
          // Play the video only when the data is loaded
        });
      } catch (error) {
        dispatch(
          error_GetFetchVideoContent(
            error.message || "Error while fetching video"
          )
        );
        console.error("Error fetching video:", error);
      }
    };
    fetchData();
  }, [videoId, dispatch, jwtToken]);

  const playerMarkers = (annotations1) => {
    console.log("playerMakers-----------------", annotations1);
    if (!playerRef.current) {
      console.error("Player instance is not available.");
      return;
    }

    const markersToAdd = annotations1.map((annotation) => {
      return {
        time: annotation.timeRange.startTime, // Directly use start time from annotation
        text: annotation?.comments?.[0]?.body.startsWith("data:audio") ? "Audio" : annotation?.comments?.[0]?.body || "Annotation", // Default text if no comment exists
      };
    });
    playerRef.current.markers({
      markers: markersToAdd,
      markerTip: {
        display: true,
        text: (marker) => marker.text, // Show annotation text on hover
      },
      markerStyle: {
        width: "5px",
        height: "20px",
        backgroundColor: "red",
        cursor: "pointer",
        borderRadius: "1px",
        bottom: "-8px",
      },
      onMarkerClick: (marker) => {
        // Seek to the marker's time when clicked
        console.log("Marker clicked at time:", marker.time, currentTime);
      },
      onMarkerReached: (marker) => {
        const time = playerRef.current.currentTime();
        console.log(
          `Reached marker at time: ${marker.time} with text: ${time}`
        );
      },
    });
  };

  useEffect(() => {
    try {
      if (playerRef.current) {
        if (annotations1.length > 0) {
          console.log("Initializing markers with annotations:", annotations1);
          playerMarkers(annotations1); // Initialize markers with annotations
        } else {
          // playerMarkers(annotations);
          console.warn("anotations--------play", annotations);

          playerMarkers([]);
        }
      } else {
        console.warn("Player instance is not available.");
      }
    } catch (error) {
      console.error("Error while initializing markers:", error.message);
    }
  }, [annotations1]);

  const updateDimensions = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const containerWidth = video.offsetWidth;
      const containerHeight = video.offsetHeight;

      const videoAspectRatio = video.videoWidth / video.videoHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      let displayWidth, displayHeight;
      let top = 0;
      let left = 0;

      if (containerAspectRatio > videoAspectRatio) {
        // Container is wider than the video aspect ratio
        displayHeight = containerHeight;
        displayWidth = videoAspectRatio * displayHeight;

        // Center the video horizontally
        left = (containerWidth - displayWidth) / 2;
      } else {
        // Container is taller than the video aspect ratio
        displayWidth = containerWidth;
        displayHeight = displayWidth / videoAspectRatio;

        // Center the video vertically
        top = (containerHeight - displayHeight) / 2;
      }

      setVideoDimensions({
        width: displayWidth,
        height: displayHeight,
        top: top,
        left: left,
      });
    }
  };

  const loadVideo = useCallback(() => {
    // Check if the video reference and `videoLoaded` are ready
    if (videoRef.current && !playerRef.current && videoLoaded) {
      try {
        const videoUrl =
          env === "standalone"
            ? "/video_js.mp4"
            : videoContent?.videoContentUrl;
        const fileType = videoUrl.split(".").pop().toLowerCase();
        const mimeType = fileType === "mov" ? "video/quicktime" : "video/mp4";
        const player = videojs(videoRef.current, {
          controls: true,
          preload: "auto",
          playbackRates: [0.5, 1, 1.5, 2],
          poster: thumbnailUrl,
          sources: [{ src: videoUrl, type: mimeType }],
          controlBar: {
            stayActive: true,
            fullscreenToggle: false,
            pictureInPictureToggle: false,
          },
          userActions: {
            doubleClick: false,
            hotkeys: false,
          },
          // responsive: true,
          // fluid: true,
          inactivityTimeout: 0,
          playsinline: true,
        });

        playerRef.current = player;
        player.on("loadeddata", () => {
          const videoWidth = player.videoWidth();
          const videoHeight = player.videoHeight();
          const videoRatio = videoWidth / videoHeight;
          console.log(`Video Ratio: ${videoRatio.toFixed(2)}`);
          setFrameRate(videoRatio);
          // Attempt to detect frame rate (if metadata or API supports it)
          let detectedFps = 30; // Default to 30 FPS

          try {
            const videoTrack = player?.tech_?.el()?.videoTracks?.[0];
            if (videoTrack && videoTrack.frameRate) {
              detectedFps = videoTrack.frameRate; // Use detected frame rate
            } else {
              setFrameRate(detectedFps);
            }
          } catch (error) {
            console.warn(
              "Failed to detect frame rate. Using default 30 FPS.",
              error
            );
          }

          // Update state with the detected frame rate
          console.log(`Frame rate set to: ${detectedFps} FPS`);
        });

        player.on("timeupdate", () => {
          if (playerRef.current) {
            const currentTime = playerRef.current.currentTime();
            setCurrentTime(currentTime);
          }
        });

        player.on("play", () => {
          console.log("Video is play");
        });
        player.on("pause", () => {
          console.log("Video is pause");
          console.log(
            "currentTimeRef.current===",
            playerRef.current.currentTime()
          );
        });

        player.on("canplay", () => {
          setLoading(false);
        });

        window.addEventListener("resize", updateDimensions);
        updateDimensions();

        player.on("error", () => {
          const error = player.error();
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case 2:
              errorMessage = "Network error: Unable to load the video.";
              break;
            case 4:
              errorMessage =
                fileType === "mov"
                  ? "Unsupported format: .mov files may not be supported in this browser."
                  : "Unsupported format: The video type is not compatible.";
              break;
          }
          console.error(errorMessage);
          toast.error(errorMessage);
        });
      } catch (initializationError) {
        console.error(
          "Error initializing Video.js player:",
          initializationError
        );
        toast.error(
          "Failed to initialize the video player. Please contact support."
        );
      }
    } else {
      console.warn(
        "Video reference is not available. Skipping player initialization."
      );
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      window.removeEventListener("resize", updateDimensions); // Clean up dimensions listener
    };
  }, [videoLoaded, videoContent?.videoContentUrl, env, thumbnailUrl]);

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      const checkInterval = 1; // Time interval in milliseconds
      const stopAtAnnotation = () => {
        const currentTime = player?.currentTime(); // Get the current time in seconds
        const frameRates = frameRate || 30; // Default to 30 FPS
        const frameDuration = 1 / frameRates; // Time per frame
        const tolerance = frameDuration / 2; // Use half a frame duration for tighter precision

        // Create a new array to hold updated annotations
        let updatedAnnotations = [...annotations];
        let needsUpdate = false;

        // Reset processed annotations if video is rewound
        updatedAnnotations = updatedAnnotations.map((annotation) => {
          if (
            annotation.processed &&
            currentTime < annotation.timeRange.startTime
          ) {
            needsUpdate = true;
            return { ...annotation, processed: false };
          }
          return annotation;
        });

        // Find the annotation that matches the current time range with tighter precision
        const matchingAnnotation = updatedAnnotations.find(
          (annotation) =>
            annotation?.timeRange?.startTime &&
            !annotation.processed &&
            Math.abs(annotation.timeRange.startTime - currentTime) <= tolerance
        );

        if (matchingAnnotation) {
          player.pause(); // Pause when annotation is found

          // Update the matching annotation's processed status
          updatedAnnotations = updatedAnnotations.map((annotation) => {
            if (annotation === matchingAnnotation) {
              needsUpdate = true;
              return { ...annotation, processed: true };
            }
            return annotation;
          });

          if (!isPaused) {
            // If the player is playing, seek to the annotation time
            player.currentTime(matchingAnnotation.timeRange.startTime); // Seek accurately
          }

          console.log(
            `Paused accurately at annotation startTime: ${matchingAnnotation.timeRange.startTime.toFixed(
              4
            )}, currentTime: ${player.currentTime().toFixed(4)}`
          );
        }

        // Update annotations state if changes were made
        if (needsUpdate) {
          setAnnotations(updatedAnnotations);
          setAnnotations1(updatedAnnotations);

          // If in standalone mode, update the Redux store
          if (env === "standalone") {
            const standAloneFeedback = {
              _id: videoId,
              feedback: updatedAnnotations,
            };
            dispatch(addStandaloneFeedback(standAloneFeedback));
          }
        }
      };

      // Set an interval to check every millisecond
      const intervalId = setInterval(stopAtAnnotation, checkInterval);

      // Cleanup the interval when the component unmounts or dependencies change
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [annotations, frameRate, isPaused, env, videoId, dispatch]);

  useEffect(() => {
    const fetchAnnotations = async () => {
      setLoading(true);
      try {
        // Reset editing states when fetching new data
        setEditingCommentId(null);
        setEditedCommentText("");

        if (!jwtToken || !videoId) {
          throw new Error(
            "Missing required parameters for fetching annotations."
          );
        }
        const response = await getCoachFeedback(jwtToken, videoId);
        const data = response.data;

        if (!Array.isArray(data)) {
          throw new Error("Invalid data format received from the server.");
        }

        console.log("Fetched annotation data:", data);
        setAnnotations(data);
        setAnnotations1(data);
        loadVideo();
      } catch (error) {
        console.error("Error fetching annotations:", error.message);
        toast.error(
          error.message ||
            "An unexpected error occurred while fetching annotations."
        );
      } finally {
        setLoading(false);
      }
    };

    const initializeStandaloneData = () => {
      setLoading(true);
      // Reset editing states when initializing standalone data
      setEditingCommentId(null);
      setEditedCommentText("");

      const standaloneVideoFeedback = standAloneFeedbacks.find(
        (feedback) => feedback._id === videoId
      );
      const feedbackData = standaloneVideoFeedback
        ? [...standaloneVideoFeedback.feedback]
        : [];
      setAnnotations(feedbackData);
      setAnnotations1(feedbackData);
      loadVideo();

      setLoading(false);
    };

    // Initialize data based on environment
    if (env === "standalone") {
      initializeStandaloneData();
    } else {
      fetchAnnotations();
    }
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [env, jwtToken, videoId, loadVideo]); // Removed standAloneFeedbacks from dependencies

  // Add a separate effect to handle updates to standAloneFeedbacks in standalone mode
  useEffect(() => {
    if (env === "standalone" && videoId) {
      const standaloneVideoFeedback = standAloneFeedbacks.find(
        (feedback) => feedback._id === videoId
      );
      const feedbackData = standaloneVideoFeedback
        ? [...standaloneVideoFeedback.feedback]
        : [];
      setAnnotations(feedbackData);
      setAnnotations1(feedbackData);
    }
  }, [env, videoId, standAloneFeedbacks]);

  useEffect(() => {
    const updateDimensions = () => {
      if (videoRef.current) {
        const video = videoRef.current;
        const containerWidth = video.offsetWidth;
        const containerHeight = video.offsetHeight;

        const videoAspectRatio = video.videoWidth / video.videoHeight;
        const containerAspectRatio = containerWidth / containerHeight;

        let displayWidth, displayHeight;
        let top = 0;
        let left = 0;

        if (containerAspectRatio > videoAspectRatio) {
          // Container is wider than the video aspect ratio
          displayHeight = containerHeight;
          displayWidth = videoAspectRatio * displayHeight;

          // Center the video horizontally
          left = (containerWidth - displayWidth) / 2;
        } else {
          // Container is taller than the video aspect ratio
          displayWidth = containerWidth;
          displayHeight = displayWidth / videoAspectRatio;

          // Center the video vertically
          top = (containerHeight - displayHeight) / 2;
        }

        setVideoDimensions({
          width: displayWidth,
          height: displayHeight,
          top: top,
          left: left,
        });
      }
    };

    const handleLoadedMetadata = () => {
      updateDimensions();
    };

    const currentVideoRef = videoRef.current;

    if (currentVideoRef) {
      currentVideoRef.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, []);

  const handleStartDrawing = (e) => {
    if (isSmallScreen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    }
    const { x, y } = e.target.getStage().getPointerPosition();
    setNewAnnotation({
      x,
      y,
      width: 0,
      height: 0,
    });
    setDrawing(true);
  };

  const handleDrawing = async (e) => {
    if (!drawing) return;

    const { x, y } = e.target.getStage().getPointerPosition();
    setNewAnnotation((prev) => ({
      ...prev,
      width: x - prev?.x,
      height: y - prev?.y,
    }));
  };

  const handleEndDrawing = async (e) => {
    if (isSmallScreen) {
      document.body.style.overflow = "auto"; // Disable scrolling
    }
    setDrawing(false);
  };

  const handleSaveAnnotation = async () => {
    if (!newAnnotation) {
      toast.error("Please select an area for annotation.");
      return;
    }

    if (!comment.trim() && !audioComment) {
      toast.error("Please provide a comment or record an audio comment.");
      return;
    }

    setIsLoading(true);

    try {
      // Reset editing state when adding new annotation
      setEditingCommentId(null);
      setEditedCommentText("");

      // Calculate the frame number
      if (
        !newAnnotation ||
        !videoDimensions ||
        !videoDimensions.width ||
        !videoDimensions.height
      ) {
        toast.error("Annotation area or video dimensions are invalid.");
        setIsLoading(false);
        return;
      }

      const relativeCoordinates = {
        x1: newAnnotation?.x / videoDimensions.width,
        y1: newAnnotation?.y / videoDimensions.height,
        x2: (newAnnotation?.x + newAnnotation.width) / videoDimensions.width,
        y2: (newAnnotation?.y + newAnnotation.height) / videoDimensions.height,
      };

      const currentTimes = playerRef.current.currentTime();

      // Create array to hold comments
      const commentsToAdd = [];

      // Add text comment if present
      if (comment.trim()) {
        commentsToAdd.push({
          _id: uuidv4(), // Generate unique ID for each comment
          body: comment.trim(),
          metaData: {
            dateTime: new Date().toISOString(),
            userId,
            userName: "coach16",
          },
          type: "text",
        });
      }

      // Check if adding a new audio feedback would exceed the limit
      if (audioComment) {
        const currentAudioFeedbacks = annotations.filter((annotation) =>
          annotation.comments[0].body.startsWith("data:audio")
        ).length;

        if (currentAudioFeedbacks >= maxAudioFeedbacks) {
          toast.error(
            `Cannot add more than ${maxAudioFeedbacks} audio feedbacks per video.`
          );
          setAudioComment(null);
          setAudioChunks([]);
          return;
        }
      }

      // Add audio comment if present
      if (audioComment) {
        commentsToAdd.push({
          _id: uuidv4(), // Generate unique ID for each comment
          body: audioComment,
          metaData: {
            dateTime: new Date().toISOString(),
            userId,
            userName: "coach16",
          },
          type: "audio",
        });
      }

      // Create separate annotation entries for each comment
      const newAnnotations = commentsToAdd.map((commentData, index) => ({
        _id: annotations.length + index + 1,
        timeRange: { startTime: currentTimes, endTime: currentTimes },
        markerDetails: { coordinates: relativeCoordinates },
        comments: [commentData],
      }));

      const updatedAnnotations = [...annotations, ...newAnnotations];
      setAnnotations(updatedAnnotations);
      setAnnotations1(updatedAnnotations);
      saveFeedback(updatedAnnotations); // Save to server
      toast.success("Feedback submitted successfully!");
      // Add markers to the player
      if (playerRef.current && playerRef.current.markers) {
        const markers = updatedAnnotations.map((annotation) => ({
          time: annotation.timeRange.startTime,
          text: annotation?.comments?.[0]?.body.startsWith("data:audio")
            ? "Audio"
            : annotation?.comments?.[0]?.body || "Annotation", // Default text if no comment exists
        }));
        playerRef.current.markers.add(markers); // Add updated markers
      }
    } catch (error) {
      console.error("Error saving annotation:", error);
      toast.error("Failed to save annotation. Please try again.");
    } finally {
      setComment("");
      setAudioComment(null);
      setNewAnnotation(null);
      setIsLoading(false);
    }
  };

  const saveFeedback = async (updatedAnnotations) => {
    if (!updatedAnnotations) {
      toast.error("Please add Makers and commnets");
      return;
    }

    setIsSaving(true); // Show loader when starting to save

    try {
      const feedbackData = updatedAnnotations.map((annotation) => ({
        ...(env === "standalone" ? { _id: annotation._id } : {}),
        timeRange: annotation.timeRange,
        markerDetails: {
          coordinates: {
            x1: annotation.markerDetails.coordinates.x1,
            y1: annotation.markerDetails.coordinates.y1,
            x2: annotation.markerDetails.coordinates.x2,
            y2: annotation.markerDetails.coordinates.y2,
          },
        },
        comments: annotation.comments.map((comment) => ({
          metaData: comment.metaData,
          body: comment.body,
        })),
      }));

      if (env === "standalone") {
        const standAloneFeedback = {
          _id: videoId,
          feedback: feedbackData,
        };
        dispatch(addStandaloneFeedback(standAloneFeedback));
        dispatch(updateStandAloneVideoStatusFunction(videoId));
        return null;
      }

      await createUpdateFeedback(jwtToken, feedbackData, videoId);
    } catch (error) {
      console.error("Error while submitting feedback:", error);
      toast.error(error.message || "Error while submitting feedback");
    } finally {
      setIsSaving(false); // Hide loader when done
    }
  };

  const isActive = (annotation) => {
    return (
      currentTime >= annotation.timeRange.startTime &&
      currentTime <= annotation.timeRange.startTime + 0.3
    );
  };

  const cancel = () => {
    navigate("/coach_flow/pending-video");
  };

  const submitData = async () => {
    if (!annotations) {
      toast.error("Please add Makers and commnets");
      return;
    }

    if (env === "standalone") {
      dispatch(updateStandAloneVideoStatusFunction("reviewed", videoId));
      navigate("/coach_flow/reviewd-video");

      return null;
    }
    try {
      await changeVideoStatus();
      toast.success("Feedback submitted successfully!");
      navigate("/coach_flow/reviewd-video");
    } catch (error) {
      console.error("Error while submitting feedback:", error);
      toast.error(error.message || "Error while submitting feedback");
    }
  };

  const changeVideoStatus = async () => {
    const formData = { state: "REVIEWED" };
    try {
      await changeVideoPostStatus(jwtToken, formData, videoId);
    } catch (error) {
      toast.error(
        error.message ||
          "An error occurred while updating the video status. Please try again."
      );
    }
  };

  const handlePauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      // TODO:check this line where is used
      setIsPaused(true);
    }
  };

  const handlePlayVideo = () => {
    // Pause any playing audio
    document.querySelectorAll('audio').forEach(audio => {
      audio.pause();
    });
    setPlayingAudioId(null);

    if (playerRef.current) {
      playerRef.current.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPaused(false);
    } else {
      console.error("Video reference is not yet ready.");
    }
  };

  const handleVideoEnd = () => {
    setIsPaused(false); // Set paused to false when the video ends
  };

  const filteredAnnotations = annotations.filter((annotation) => {
    const currentTime = playerRef.current?.currentTime(); // Get the current time of the video
    const { startTime, endTime } = annotation.timeRange; // Extract start and end times of the annotation
    return currentTime >= startTime && currentTime <= endTime;
  });

  const toggleComment = (id) => {
    console.log("Id here", id);
    setExpandedCommentId((prev) => (prev === id ? null : id)); // Toggle between the clicked ID and null
  };

  const handleUpdateComment = async (annotation) => {
    try {
      if (!editedCommentText.trim()) {
        toast.error("Comment cannot be empty");
        return;
      }

      // Create updated annotation with new comment
      const updatedAnnotation = {
        ...annotation,
        comments: [
          {
            _id: uuidv4(), // Generate unique ID for each comment
            ...annotation.comments[0],
            body: editedCommentText.trim(),
            metaData: {
              ...annotation.comments[0].metaData,
              dateTime: new Date().toISOString(),
            },
          },
        ],
      };

      // Update annotations array
      const updatedAnnotations = annotations1.map((a) =>
        a._id === annotation._id ? updatedAnnotation : a
      );

      // Update local state
      setAnnotations(updatedAnnotations);
      setAnnotations1(updatedAnnotations);

      // Save to backend
      await saveFeedback(updatedAnnotations);

      // Reset edit state
      setEditingCommentId(null);
      setEditedCommentText("");

      toast.success("Comment updated successfully");
    } catch (error) {
      console.error("Error updating comment:", error);
      toast.error("Failed to update comment. Please try again.");
    }
  };

  const handleDeleteClick = (annotation) => {
    if (playerRef.current) {
      playerRef.current.pause();
    }
    setAnnotationToDelete(annotation);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    if (playerRef.current) {
      playerRef.current.play();
    }
    setDeleteDialogOpen(false);
    setAnnotationToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!annotationToDelete) return;
    if (playerRef.current) {
      playerRef.current.play();
    }
    setDeleteDialogOpen(false);
    setIsDeleting(true);

    try {
      // Filter out the annotation with the matching ID
      const updatedAnnotations = annotations.filter(
        (a) => a._id !== annotationToDelete._id
      );

      // Update local state
      setAnnotations(updatedAnnotations);
      setAnnotations1(updatedAnnotations);

      // Update markers
      if (playerRef.current) {
        // First destroy existing markers
        playerRef.current.markers.destroy();

        // Reinitialize markers plugin
        playerRef.current.markers({
          markerTip: {
            display: true,
            text: (marker) => marker.text,
          },
          markerStyle: {
            width: "5px",
            height: "20px",
            backgroundColor: "red",
            cursor: "pointer",
            borderRadius: "1px",
            bottom: "-8px",
          },
          markers: [], // Initialize with empty array
        });

        // Only add markers if there are annotations
        if (updatedAnnotations.length > 0) {
          const markers = updatedAnnotations.map((annotation) => ({
            time: annotation.timeRange.startTime,
            text: annotation?.comments?.[0]?.body.startsWith("data:audio")
              ? "Audio"
              : annotation?.comments?.[0]?.body || "Annotation", // Default text if no comment exists
          }));

          // Add new markers
          playerRef.current.markers.add(markers);
        }
      }

      // Save to backend
      await saveFeedback(updatedAnnotations);

      toast.success("Comment deleted successfully");
    } catch (error) {
      console.error("Error deleting comment:", error);
      toast.error("Failed to delete comment. Please try again.");
    } finally {
      setIsDeleting(false);
      setAnnotationToDelete(null);
    }
  };

  const handleStartRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setMediaStream(stream); // Store the stream
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          const chunks = [];

          recorder.ondataavailable = (event) => {
            chunks.push(event.data);
          };

          recorder.onstop = async () => {
            stopMediaTracks(); // Stop media tracks after recording
            // Only process the audio if recording wasn't stopped due to timeout
            if (!recorder.exceededMaxDuration) {
              setAudioChunks(chunks);
              const audioBlob = new Blob(chunks, { type: "audio/wav" });
              const base64MP3 = await convertWavToMp3(audioBlob);
              setAudioComment(base64MP3);
            }
          };

          recorder.start();
          setIsRecording(true);

          // Stop recording after maxRecordingDuration seconds
          setTimeout(() => {
            if (recorder.state === "recording") {
              recorder.exceededMaxDuration = true; // Mark that we stopped due to timeout
              recorder.stop();
              setIsRecording(false);
              setAudioComment(null);
              setAudioChunks([]);
              toast.error(
                `Please record the audio again under the duration of ${maxRecordingDuration} seconds.`
              );
            }
          }, (maxRecordingDuration + 1) * 1000);
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
          toast.error("Error accessing microphone.");
        });
    } else {
      toast.error("Audio recording is not supported in this browser.");
    }
  };

  const stopMediaTracks = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setIsRecording(false);
      stopMediaTracks(); // Stop media tracks after recording
    }
  };

  // Converts WAV to MP3 using FFmpeg.js
  const convertWavToMp3 = async (audioBlob) => {
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }

    // Convert Blob to ArrayBuffer
    const fileData = await audioBlob.arrayBuffer();

    // Write WAV file to FFmpeg's virtual filesystem
    await ffmpeg.writeFile("input.wav", new Uint8Array(fileData));

    // Convert WAV to MP3 (64kbps)
    await ffmpeg.exec(["-i", "input.wav", "-b:a", "64k", "output.mp3"]);

    // Read the MP3 file from FFmpeg's filesystem
    const mp3Data = await ffmpeg.readFile("output.mp3");

    // Convert MP3 file to a Blob
    const mp3Blob = new Blob([mp3Data], { type: "audio/mp3" });

    // Convert Blob to Base64
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(mp3Blob);
      reader.onloadend = () => resolve(reader.result);
    });
  };

  const handleDeleteAudio = () => {
    setAudioComment(null);
    setAudioChunks([]);
  };

  const handleAudioPlay = (audioElement, annotation) => {
    // Pause video if playing
    if (playerRef.current) {
      playerRef.current.pause();
    }
    
    // Pause any other playing audio
    document.querySelectorAll('audio').forEach(audio => {
      if (audio !== audioElement) {
        audio.pause();
      }
    });

    setPlayingAudioId(annotation._id);
    
    // Add ended event listener to reset playingAudioId
    audioElement.addEventListener('ended', () => {
      setPlayingAudioId(null);
    });
  };

  // Add cleanup when component unmounts
  useEffect(() => {
    return () => {
      setEditingCommentId(null);
      setEditedCommentText("");
    };
  }, []);

  return (
    <>
      <section className={styles.feedback_video}>
        <h1>
          {firstname || ""} {lastname || ""}
          <span> @{dataFormatter(reviewPost?.updatedAt)}</span>
        </h1>
        <div className={styles.video_section}>
          <div className={styles.video_container}>
            {!videoLoaded && (
              <div className={styles.loader}>
                <div className={styles.spinner}></div>
              </div>
            )}
            <div className={styles.video_controls}>
              <video
                key={videoKey}
                ref={videoRef}
                onPause={handlePauseVideo}
                onPlay={handlePlayVideo}
                onEnded={handleVideoEnd}
                className="video-js"
                onLoadedMetadata={() => {
                  // Initial setting of video dimensions
                  setVideoDimensions({
                    width: videoRef.current.offsetWidth,
                    height: videoRef.current.offsetHeight,
                  });
                  updateDimensions();
                }}
                // style={{ objectFit: "fill" }}
                poster={thumbnailUrl} // Placeholder thumbnail
              />
            </div>
            <Stage
              ref={konvaRef}
              width={videoDimensions.width}
              height={videoDimensions.height}
              className={styles.konva_stage}
              onMouseDown={handleStartDrawing}
              onMouseMove={handleDrawing}
              onMouseUp={handleEndDrawing}
              onTouchStart={handleStartDrawing}
              onTouchMove={handleDrawing}
              onTouchEnd={handleEndDrawing}
              style={{
                position: "absolute",
                top: `${videoDimensions.top}px`,
                left: `${videoDimensions.left}px`,
                pointerEvents: isPaused ? "auto" : "none", // Disable pointer events when video is not paused
                cursor: drawing ? "crosshair" : "default", // Show crosshair cursor when drawing
                touchAction: "none", // Prevent default touch interactions (like scrolling)
              }}
            >
              <Layer>
                {/* Render filtered annotations */}
                {filteredAnnotations.map((annotation, index) => {
                  const x =
                    annotation.markerDetails.coordinates.x1 *
                    videoDimensions.width;
                  const y =
                    annotation.markerDetails.coordinates.y1 *
                    videoDimensions.height;
                  const width =
                    (annotation.markerDetails.coordinates.x2 -
                      annotation.markerDetails.coordinates.x1) *
                    videoDimensions.width;
                  const height =
                    (annotation.markerDetails.coordinates.y2 -
                      annotation.markerDetails.coordinates.y1) *
                    videoDimensions.height;

                  return (
                    <Rect
                      key={index}
                      x={x}
                      y={y}
                      width={width}
                      height={height}
                      fill="rgba(255, 255, 0, 0)"
                      stroke="yellow"
                    />
                  );
                })}

                {/* New annotation (if exists) */}
                {newAnnotation && (
                  <Rect
                    x={newAnnotation.x}
                    y={newAnnotation.y}
                    width={newAnnotation.width}
                    height={newAnnotation.height}
                    fill="rgba(255, 0, 0, 0.04)"
                    stroke="yellow"
                  />
                )}
              </Layer>
            </Stage>
          </div>

          <div className={styles.sidebar}>
            <h4>Add Feedback</h4>
            <div className={styles.feedback_input_container}>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Feedback"
              />
              <button
                className={`${styles.audio_button} ${
                  isRecording ? styles.recording : ""
                }`}
                onClick={
                  isRecording ? handleStopRecording : handleStartRecording
                }
              >
                <FaMicrophone />
              </button>
            </div>
            {audioComment && (
              <div className={styles.audio_preview}>
                <audio
                  controls
                  src={audioComment}
                  onPlay={(e) => handleAudioPlay(e.target, { _id: 'preview' })}
                />
                <button
                  className={styles.delete_button}
                  onClick={handleDeleteAudio}
                >
                  <FaTrashIcon />
                </button>
              </div>
            )}
            <button
              className={styles.save_button}
              onClick={handleSaveAnnotation}
              disabled={!comment.trim() && !audioComment} // Disable when no comment or audio
            >
              {isLoading ? (
                <div className={styles.loader}>
                  <div className={styles.spinner}></div>
                </div>
              ) : (
                "Save Feedback"
              )}
            </button>

            <div className={styles.annotations_list}>
              <h4>Feedback List</h4>
              {loading ? ( // Show loader when data is loading
                <div className={styles.loader}>Loading...</div>
              ) : annotations.length === 0 ? (
                // Show "Data Not Found" message when annotations array is empty
                <div className={styles.no_data}>
                  <p>Feedback not found</p>
                </div>
              ) : (
                annotations
                  .sort((a, b) => {
                    const aActive = isActive(a);
                    const bActive = isActive(b);

                    if (aActive && !bActive) return -1;
                    if (!aActive && bActive) return 1;
                    return 0;
                  })
                  .map((annotation) => {
                    const isExpanded = expandedCommentId === annotation._id;
                    const isEditing = editingCommentId === annotation._id;
                    const commentText = annotation.comments[0].body;

                    return (
                      <div
                        key={annotation.id}
                        className={styles.annotation_item}
                      >
                        <div
                          className={styles.annotation_header}
                          style={{
                            backgroundColor: isActive(annotation)
                              ? "yellow"
                              : "#fff",
                            border: isActive(annotation)
                              ? "2px solid green"
                              : "1px solid gray",
                          }}
                        >
                          <div className={styles.name}>
                            <p
                              className={styles.time_range}
                              style={{
                                color: isActive(annotation) ? "green" : "gray",
                              }}
                            >
                              {annotation.timeRange.startTime.toFixed(2)}s
                            </p>

                            {isEditing ? (
                              <div className={styles.edit_container}>
                                <textarea
                                  value={editedCommentText}
                                  onChange={(e) =>
                                    setEditedCommentText(e.target.value)
                                  }
                                  className={styles.edit_textarea}
                                />
                                <div className={styles.edit_actions}>
                                  <button
                                    onClick={() =>
                                      handleUpdateComment(annotation)
                                    }
                                    className={styles.save_edit}
                                    title="Save"
                                  >
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      style={{ color: "#28a745" }}
                                    />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setEditingCommentId(null);
                                      setEditedCommentText("");
                                    }}
                                    className={styles.cancel_edit}
                                    title="Cancel"
                                  >
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      style={{ color: "#dc3545" }}
                                    />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                {commentText.startsWith("data:audio") ? (
                                  <audio
                                    controls
                                    key={annotation._id}
                                    onPlay={(e) => handleAudioPlay(e.target, annotation)}
                                  >
                                    <source
                                      src={commentText}
                                      type="audio/wav"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                ) : (
                                  <p
                                    className={styles.comment_text}
                                    style={{
                                      color: isActive(annotation)
                                        ? "green"
                                        : "gray",
                                    }}
                                  >
                                    {isExpanded
                                      ? commentText
                                      : `${commentText.slice(0, 50)}${
                                          commentText.length > 50 ? "..." : ""
                                        }`}
                                  </p>
                                )}
                                {commentText.length > 50 &&
                                  !commentText.startsWith("data:audio") && (
                                    <button
                                      onClick={() =>
                                        toggleComment(annotation._id)
                                      }
                                      className={styles.seeMoreButton}
                                    >
                                      {isExpanded ? "See Less" : "See More"}
                                    </button>
                                  )}

                                <div className={styles.comment_actions}>
                                  {!commentText.startsWith("data:audio") && (
                                    <button
                                      onClick={() => {
                                        setEditingCommentId(annotation._id);
                                        setEditedCommentText(
                                          annotation.comments[0].body
                                        );
                                      }}
                                      className={styles.edit_button}
                                      disabled={
                                        editingCommentId !== null &&
                                        editingCommentId !== annotation._id
                                      }
                                    >
                                      <FontAwesomeIcon icon={faPenToSquare} />
                                    </button>
                                  )}
                                  <button
                                    onClick={() =>
                                      handleDeleteClick(annotation)
                                    }
                                    className={styles.delete_button}
                                    disabled={editingCommentId !== null}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <button className={styles.cancel} onClick={cancel}>
            Cancel
          </button>
          <button
            disabled={
              annotations.length === 0 || videoContent.isStartedvideoContent
            }
            className={styles.submit}
            onClick={submitData}
          >
            Publish Report
          </button>
        </div>
      </section>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Feedback"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this feedback? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full Page Loader */}
      {(isDeleting || isSaving) && (
        <div className={styles.fullPageLoader}>
          <div className={styles.spinner}></div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Feedback;
