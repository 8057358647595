import React from 'react'
import styles from './Stats.module.scss'

const Stats = () => {
    return (
        <div className={styles.Stats}>
            <div className={styles.heading}>
                <h5>Individual</h5>
            </div>

            <hr />

        </div>
    )
}

export default Stats