import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./CoachFeedback.scss";
import "videojs-markers/dist/videojs.markers.css";
import "videojs-markers";
import { useSelector } from "react-redux";
import { getCoachFeedback } from "../../redux/network/coachAPI";
import { Stage, Layer, Rect } from "react-konva";

export default function CoachFeedback({ videoId }) {
  const env = process.env.REACT_APP_ENV;
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDimensions, setVideoDimensions] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [annotationData, setAnnotationData] = useState([]);
  const [activeComments, setActiveComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const videoContent = useSelector(state => state.video.videoContent);
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const userId = localStorage.getItem("BQS__ID");

  //standAloneFeedback
  const standAloneFeedbacks = useSelector(state => state.standAlone.feedbacks.feedbackData);
  console.log("standAloneFeedbacks", standAloneFeedbacks)


  useEffect(() => {
    if (videoRef.current) {
      setVideoDimensions({
        width: videoRef.current.clientWidth,
        height: videoRef.current.clientHeight,
      });
    }
  }, [videoRef.current?.clientWidth, videoRef.current?.clientHeight]);

  useEffect(() => {
    if (env !== "standalone") {
      const fetchAnnotations = async () => {
        try {
          const response = await getCoachFeedback(jwtToken, videoId);
          const data = response.data || [];
          setAnnotationData(data || []);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
      fetchAnnotations();
    } else {
      const standaloneVideoFeedback = standAloneFeedbacks.find((feedback) => feedback._id === videoId)
      console.log("standaloneVideoFeedback", standaloneVideoFeedback)
      console.log("standaloneVideoFeedback feedback", standaloneVideoFeedback.feedback)

      setAnnotationData(standaloneVideoFeedback.feedback || [])
      setLoading(false);
    }

  }, [env, jwtToken, videoId]);

  useEffect(() => {
    if (videoRef.current && annotationData.length > 0) {
      const vjsPlayer = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: "auto",
      });

      // Initialize markers
      vjsPlayer?.markers({
        markers: annotationData.map((annotation) => ({
          time: annotation.timeRange.startTime,
          text: "Annotation " + annotation.timeRange.startTime,
        })),
        markerStyle: {
          width: "5px",
          backgroundColor: "red",
        },
        onMarkerReached: (marker) => {
          vjsPlayer?.pause();
          // Adjust annotations when a marker is reached
          setCurrentTime(marker.time);

          // Resume playback after 10 seconds
          setTimeout(() => {
            vjsPlayer?.play();
          }, 4000);
        },
      });

      setPlayer(vjsPlayer);

      const timeInterval = setInterval(() => {
        setCurrentTime(vjsPlayer.currentTime());
      }, 4000); // Update every second

      return () => {
        clearInterval(timeInterval);
        if (vjsPlayer) {
          vjsPlayer?.dispose();
        }
      };
    }
  }, [videoRef, annotationData]);

  useEffect(() => {
    const relevantComments = annotationData
      .filter(
        (annotation) =>
          currentTime >= annotation.timeRange.startTime &&
          currentTime <= annotation.timeRange.endTime
      )
      .map((annotation) => annotation.comments)
      .flat();

    setActiveComments(relevantComments || []);
  }, [currentTime, annotationData]);

  // Convert annotation data to Konva shapes (rectangles)
  const getAnnotationsForCurrentTime = () => {
    return annotationData.filter(
      (annotation) =>
        currentTime >= annotation.timeRange.startTime &&
        currentTime <= annotation.timeRange.endTime
    );
  };

  // Map annotations to Konva Rectangles
  const drawAnnotations = () => {
    const annotations = getAnnotationsForCurrentTime();
    return annotations.map((annotation, index) => {
      const { coordinates } = annotation.markerDetails;
      return (
        <Rect
          key={index}
          x={coordinates.x1}
          y={coordinates.y1}
          width={coordinates.x2 - coordinates.x1}
          height={coordinates.y2 - coordinates.y1}
          fill="rgba(255, 0, 0, 0.3)"
          stroke="red"
          strokeWidth={2}
          draggable
        />
      );
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleVideoEnd = () => {
    setIsPaused(false); // Set paused to false when the video ends
  };
  const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);

    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  const reviewPost = JSON.parse(localStorage.getItem("reviewPost"))

  return (
    <section>
      <h1>Feedback video</h1>
      <p >{dataFormatter(reviewPost.updatedAt)}</p>
      <div className="coach-feedback" style={{ position: "relative", display: "grid", gridTemplateColumns: "2fr 1fr", gap: "1rem", margin: "1rem" }}>
        <div >
          <div style={{ width: "600px", height: "400px", borderRadius: "10px", overflow: "hidden" }}>
            <video
              ref={videoRef}
              className="video-js vjs-default-skin"
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
              controls
              onEnded={handleVideoEnd}
            >
              <source
                src={env === "standalone" ? "/banner_video.mp4" : videoContent.videoContentUrl}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <Stage width={videoDimensions.width} height={videoDimensions.height}
            style={{
              position: "absolute", top: 0, left: 0,
              pointerEvents: isPaused ? "auto" : "none",
            }}>
            <Layer>
              {drawAnnotations()}
            </Layer>
          </Stage>
        </div>

        <div className="comments-container" style={{ backgroundColor: "#D9F0DE", borderRadius: "5px", padding: "1rem", overflowY: "auto" }}>
          {activeComments.length > 0 ? (
            activeComments.map((comment, index) => (
              <div key={index} className="comment-box" style={{ marginBottom: "20px", borderBottom: "1px solid #ddd", paddingBottom: "15px" }}>
                <div className="comment-header" style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "#007bff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {comment.metaData.userName.charAt(0).toUpperCase()}
                  </div>
                  <span style={{ fontWeight: "bold", color: "#333", textTransform: "capitalize" }}>{comment.metaData.userName}</span>
                </div>

                <div className="comment-text" style={{ background: "rgba(0, 0, 0, 0.1)", color: "#333", padding: "10px", borderRadius: "5px", fontSize: "14px" }}>
                  {comment.body}
                </div>
                <span style={{ marginLeft: "10px", fontSize: "12px", color: "gray", textAlign: "end" }}>
                  {new Date(comment.metaData.dateTime).toLocaleString()}
                </span>
              </div>
            ))
          ) : (
            <div style={{ color: "gray", padding: "10px" }}>No comments at this time.</div>
          )}
        </div>
      </div>
    </section>
  );
}

