import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../VideoPlayerModel/VideoPlayerModel.module.scss';
import { setAlertMessage, setAlertType } from '../../../redux/reducers/alertSlice';
import { changeVideoPostStatus } from '../../../redux/network/coachAPI';
import { updateStandAloneVideoStatusFunction } from '../../../redux/network/standAloneApi';
import {
  error_GetFetchVideoContent,
  isStarted_GetFetchVideoContent,
  success_GetFetchVideoContent,
  toggleVideoSortDropdown,
} from '../../../redux/reducers/videoSlice';
import { useNavigate } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { GET_POST_CONTENT } from '../../../config/endpoints';
import { getFetchGamePostFileThumbnail } from '../../../redux/network/videoApi';

export default function Requestfeedback() {
  const env = process.env.REACT_APP_ENV;
  const jwtToken = localStorage.getItem('BQS_TOKEN');
  const reviewPostId = localStorage.getItem('reviewPostId');
  const reviewPost = JSON.parse(localStorage.getItem('reviewPost'));
  const state = reviewPost.state;
  const videoContent = useSelector((state) => state.video.videoContent);
  const navigate = useNavigate();
  const { isStarted, isError, error, data } = useSelector(state => state.video.video);
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const thumbnailUrl = videoThumbnails[reviewPostId] || "https://www.fisu.net/app/uploads/2023/09/badminton.jpg";
  const userId = localStorage.getItem("BQS__ID");
  const [videoLoaded, setVideoLoaded] = useState(false); // Track if video is fully loaded
  const [videoKey, setVideoKey] = useState(Date.now()); // Force re-render video element
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if (data?.length > 0 && jwtToken && env !== "standalone") {

      const fetchThumbnail = async (reviewPostId, tnPath) => {
        try {
          const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, tnPath);
          setVideoThumbnails(prev => ({ ...prev, [reviewPostId]: thumbnailUrl }));
        } catch (error) {
          console.error('Error fetching thumbnail:', error);
        }
      };
      data?.forEach((item) => {
        fetchThumbnail(item._id, item.game[0]?.tnPath);
      });
    }
  }, [data, jwtToken, userId]);

  useEffect(() => {
    if (videoRef.current && videoLoaded) {
      // Dispose of the previous instance if it exists
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }

      // Set video source URL based on the environment (standalone or other)
      const videoSourceUrl = env === 'standalone' ? '/video_js.mp4' : videoContent.videoContentUrl;

      // Initialize video player
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        preload: 'auto',
        playbackRates: [0.5, 1, 1.5, 2],
        poster: thumbnailUrl,
        sources: [
          {
            src: videoSourceUrl, // Use the video source URL conditionally
            type: 'video/mp4',
          },
        ],
        controlBar: {
          stayActive: true,
          fullscreenToggle: false,
          pictureInPictureToggle: false,
        },
        userActions: {
          doubleClick: false,
          hotkeys: false,
        },
        inactivityTimeout: 0,
        playsinline: true,
      });

      // Add event listeners
      playerRef.current.on('loadeddata', () => {
        setVideoLoaded(true);
      });

      // Clean up when the component is unmounted or dependencies change
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    }
  }, [videoLoaded, videoContent.videoContentUrl]);


  const changeVideoStatus = async () => {
    if (env === 'standalone') {
      const videoState = 'review_requested';
      dispatch(updateStandAloneVideoStatusFunction(videoState, reviewPostId));
      navigate('/game-posts/game-videos');
      return;
    }

    const formData = { state: 'REVIEW_REQUESTED' };

    try {
      const response = await changeVideoPostStatus(jwtToken, formData, reviewPostId);
      navigate('/game-posts/game-videos');
      dispatch(toggleVideoSortDropdown());

      setTimeout(() => {
        dispatch(setAlertMessage(response?.data?.message));
        dispatch(setAlertType('success'));
      }, 1000);
    } catch (error) {
      dispatch(setAlertMessage(error.message));
      dispatch(setAlertType('error'));
    }
  };

  const cancel = () => {
    navigate('/game-posts/game-videos');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const url = `${GET_POST_CONTENT}?postId=${reviewPostId}&type=game`;
        const url = localStorage.getItem("videoLinkInObjectStore");
        dispatch(isStarted_GetFetchVideoContent());

        const response = await fetch(url, {
          method: 'GET'
          // ,
          // headers: {
          //   Authorization: `Bearer ${jwtToken}`,
          // },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch video');
        }

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });

        const responseBlob = await new Response(stream).blob();
        const file = new File([responseBlob], 'video.mp4', { type: 'video/mp4' });
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener('load', () => {
          const res = fr.result;
          dispatch(success_GetFetchVideoContent(res));
          setVideoLoaded(true);
          setVideoKey(Date.now()); // Update key to force re-render
        });
      } catch (error) {
        dispatch(error_GetFetchVideoContent(error.message || 'Error while fetching video'));
        console.error('Error fetching video:', error);
      }
    };
    fetchData();
  }, [reviewPostId, dispatch, jwtToken]);


  return (
    <div className={styles.videoContainer} style={{ position: 'relative' }}>
      <div className={styles.playerContainer}>
        {/* Show loader */}
        {!videoLoaded && (
          <div className={styles.loader}>
            <div className={styles.spinner}></div>
          </div>
        )}
        
        <video
          key={videoKey}
          ref={videoRef}
          className="video-js"
          poster={thumbnailUrl}
          
        />

      </div>
      {state === 'created' && (
        <div className={styles.buttonContainer}>
          <button className={styles.buttonOutline} onClick={cancel}>
            Cancel
          </button>
          <button className={styles.buttonPrimary} onClick={changeVideoStatus}>
            Submit Video
          </button>
        </div>
      )}
    </div>
  );
}
