import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './VideoPlayerModel.module.scss'
import { useNavigate } from 'react-router-dom';
import { setAlertMessage, setAlertType } from '../../redux/reducers/alertSlice';
import { POST_UPDATE_REVIEW_SELECTION } from '../../config/endpoints';
import axios from 'axios';
import { changeVideoPostStatus } from '../../redux/network/coachAPI';
import { toast } from 'react-toastify';
import { getVideos } from '../../redux/network/videoApi';
import { toggleVideoSortDropdown } from '../../redux/reducers/videoSlice';
import { updateStandAloneVideoStatusFunction } from '../../redux/network/standAloneApi';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen, selectedVideo }) {
    const env = process.env.REACT_APP_ENV;
    const jwtToken = localStorage.getItem("BQS_TOKEN");
    const reviewPostId = localStorage.getItem("reviewPostId");

    console.log("selectedVideo", selectedVideo)
    const videoContent = useSelector(state => state.video.videoContent);
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState("")

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const dispatch = useDispatch();

    const navigate = useNavigate();


    const handleSubmit = () => {
        if (!reviewPostId || !jwtToken) {
            setError("Missing review post ID or JWT token.");
            dispatch(setAlertMessage("Missing review post ID or JWT token."));
            dispatch(setAlertType("error"));
            return;
        }

        const formData = {
            "strokeTechnique": {
                "forehand": [
                    "Half smash"
                ],
                "backhand": [
                    "Simple keep"
                ],
                "overhead": [
                    "Half smash"
                ]
            }
        };



        const url = `${POST_UPDATE_REVIEW_SELECTION}/?postId=${reviewPostId}`;

        const stringifyFd = JSON.stringify(formData);

        axios.post(url, stringifyFd, {
            headers: {
                "Authorization": `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                console.log("PostUpdateReviewSelection", res);
                if (res.data.code === 200) {
                    setIsSuccess(true);
                    dispatch(setAlertMessage(res.data.message));
                    dispatch(setAlertType("success"));
                    setOpen(false)

                } else if (res.data.code === 401) {
                    localStorage.clear();
                    dispatch(setAlertMessage("Unauthorized"));
                    dispatch(setAlertType("error"));
                } else {
                    throw new Error(res.data.message || "Something went wrong!");
                }
            })
            .catch(error => {
                console.error("Error", error);
                setError(error?.response?.data?.message || error?.message || "Something went wrong!");
                dispatch(setAlertMessage(error?.response?.data?.message || error?.message || "Something went wrong!"));
                dispatch(setAlertType("error"));
            });
    };

    const changeVideoStatus = async () => {

        if (env === "standalone") {
            const videoState = "review_requested"
            dispatch(updateStandAloneVideoStatusFunction(videoState, reviewPostId))
            setOpen(false)
            return null;
        }

        const formData = {
            state: "REVIEW_REQUESTED",
        };
        try {
            const response = await changeVideoPostStatus(jwtToken, formData, reviewPostId);
            // navigate("/game-posts/game-reports")

            dispatch(toggleVideoSortDropdown())


            setTimeout(() => {
                dispatch(setAlertMessage(response?.data?.message));
                dispatch(setAlertType("success"));
                setOpen(false)

            }, 1000);

        } catch (error) {
            dispatch(setAlertMessage(error.message));
            dispatch(setAlertType("error"));
        }
    };





    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <div className='videoContainer' style={{ backgroundColor: "black", position: "relative" }}>

                {
                    videoContent.isStartedvideoContent && <button aria-label="Close video" style={{ position: "absolute", right: "5%", top: "10px", backgroundColor: "transparent", border: "none", outline: "none", fontSize: "2rem", color: "white" }} onClick={handleClose}>x</button>
                }

                {
                    videoContent.isStartedvideoContent ? (
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", color: 'white', height: "100vh" }}>
                            <CircularProgress />
                            <p style={{ paddingLeft: "10px" }}>Loading Video ....</p>
                        </Box>
                    ) : videoContent.isvideoContentError ? (
                        <p style={{ color: "red" }}>{videoContent.videoContentError}</p>
                    ) : (
                        <div className={styles.playerContainer}>
                            <video controls autoPlay muted>
                                <source src={env === "standalone" ? "/banner_video.mp4" : videoContent.videoContentUrl} type="video/mp4"></source>
                            </video>

                            <div className={styles.buttonContainer}>
                                <button className={styles.buttonOutline} onClick={handleClose}>Cancel</button>
                                {
                                    selectedVideo?.state === "created" && <button className={styles.buttonPrimary} onClick={changeVideoStatus}>Submit video</button>
                                }

                            </div>




                        </div>
                    )
                }
            </div>
        </Dialog>
    );
}
