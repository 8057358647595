import { createSlice } from "@reduxjs/toolkit";

export const videoSlice = createSlice({
    name: "video",
    initialState: {
        video: {
            isStarted: false,
            isError: false,
            error: '',
            data: [],
        },
        videoContent: {
            isStartedvideoContent: false,
            isvideoContentError: false,
            videoContentError: '',
            videoContentUrl: '',
        },
        videosCount: {
            isStarted: false,
            isError: false,
            error: '',
            data: '',
        },
        videoSortDropdown: {
            resetToDefault: false
        }
    },
    reducers: {
        isStarted_GetVideo: (state) => {
            state.video.isStarted = true;
            state.video.isError = false;
        },
        success_GetVideo: (state, action) => {
            console.log('GET-Video: ', action.payload)
            let { payload } = action;
            state.video.isStarted = false;
            state.video.isError = false;
            state.video.error = '';
            state.video.data = payload;
        },
        error_GetVideo: (state, action) => {
            console.log(action)
            let { payload } = action;
            state.video.isStarted = false;
            state.video.isError = true;
            state.video.error = payload;
        },
        isStarted_GetFetchVideoContent: (state) => {
            state.videoContent.isStartedvideoContent = true;
            state.videoContent.isvideoContentError = false;
        },
        success_GetFetchVideoContent: (state, action) => {
            // console.log('GET-Video: ', action.payload)
            let { payload } = action;
            state.videoContent.isStartedvideoContent = false;
            state.videoContent.isvideoContentError = false;
            state.videoContent.videoContentError = '';
            state.videoContent.videoContentUrl = payload;
        },
        error_GetFetchVideoContent: (state, action) => {
            console.log(action)
            let { payload } = action;
            state.videoContent.isStartedvideoContent = false;
            state.videoContent.isvideoContentError = true;
            state.videoContent.videoContentError = payload;
        },

        isStarted_GetVideosCount: (state) => {
            state.videosCount.isStarted = true;
            state.videosCount.isError = false;
        },
        success_GetVideosCount: (state, action) => {
            console.log('GET-Video: ', action.payload)
            let { payload } = action;
            state.videosCount.isStarted = false;
            state.videosCount.isError = false;
            state.videosCount.error = '';
            state.videosCount.data = payload;
        },
        error_GetVideosCount: (state, action) => {
            console.log(action)
            let { payload } = action;
            state.videosCount.isStarted = false;
            state.videosCount.isError = true;
            state.videosCount.error = payload;
        },

        deleteVideoPost: (state, action) => {
            let { payload } = action;
            const updatedData = state.video.data.filter((video) => video._id !== payload);
            state.video.data = updatedData;

            if (state.videosCount.data > 0) {
                state.videosCount.data -= 1;
            }
        },
        toggleFavVideoPost: (state, action) => {
            const postId = action.payload;
            const post = state.video.data.find(post => post._id === postId);
            if (post) {
                post.fav = !post.fav;
            }
        },
        toggleVideoSortDropdown: (state, action) => {
            state.videoSortDropdown.resetToDefault = !state.videoSortDropdown.resetToDefault
        },
        AddStandAloneVideo: (state, action) => {
            console.log('GET-Video: ', action.payload)
            let { payload } = action;
            state.video.data = [...state.video.data, payload]
        },
        UpdateStandAloneVideoStatus: (state, action) => {
            const { videoState, videoId } = action.payload;
            const video = state.video.data.find(video => video._id === videoId);
            if (video) {
                video.state = videoState;
            }

        }



    }
})

export const { isStarted_GetVideo, success_GetVideo, error_GetVideo, isStarted_GetFetchVideoContent, success_GetFetchVideoContent, error_GetFetchVideoContent, isStarted_GetVideosCount, success_GetVideosCount, error_GetVideosCount, deleteVideoPost, toggleFavVideoPost, toggleVideoSortDropdown, AddStandAloneVideo, UpdateStandAloneVideoStatus } = videoSlice.actions

export default videoSlice.reducer;

