import { GET_FETCH_REVIEW_POST, GET_FETCH_REVIEW_POST_FILE } from "../../config/endpoints";
import { setIsLoginDialog } from "../reducers/loginSlice";
import { error_fetchedPostFile, error_getPostsList, isStarted_fetchedPostFile, isStarted_getPostsList, success_fetchedPostFile, success_getPostsList } from "../reducers/reportSlice";

export const getfetchreviewpost = (jwtToken, postId) => {
    console.log("HITTING - GET_FETCH_REVIEW_POST jwtToken", jwtToken, postId);
    console.log("HITTING - GET_FETCH_REVIEW_POST postId", postId);
    return dispatch => {
        const url = `${GET_FETCH_REVIEW_POST}${postId}`
        const apiUrl = new URL(url);
        console.log(apiUrl);
        dispatch(isStarted_getPostsList());
        fetch(apiUrl, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
        }).then(res => {
            if (res.status === 401) {
                //Anauthorized user
                dispatch(setIsLoginDialog(true))
                localStorage.clear();
                return;
            }

            console.log("res", res)
            return res.json()
        }).then(response => {
            console.log("response", response)
            dispatch(success_getPostsList(response.data));
        }).catch(error => {
            console.error(error.message);
            dispatch(error_getPostsList(error?.message || "Error while fetching post review"));
        });
    }
}


export const getFetchReviewPostFile = (jwtToken, postId, file) => {
    console.log("HITTING - getFetchPostContent jwtToken", jwtToken);
    console.log("HITTING - getFetchPostContent postId", postId, file);
    console.log("HITTING - getFetchPostContent postId file", file);
    return dispatch => {
        const url = `${GET_FETCH_REVIEW_POST_FILE}&postId=${postId}&file=${file}`
        const apiUrl = new URL(url);
        console.log(apiUrl);
        dispatch(isStarted_fetchedPostFile());
        fetch(apiUrl, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
        }).then(res => {
            if (res.status === 401) {
                //Anauthorized user
                dispatch(setIsLoginDialog(true))
                localStorage.clear();
                return;
            }


            if (res.ok) {
                return res.blob()
            } else {
                throw new Error(res.statusText)
            }
        }).then(blob => {
            console.log(blob)
            const file = new File([blob], 'video', { type: 'video/mp4' });
            const fr = new FileReader()
            fr.readAsDataURL(file)
            fr.addEventListener("load", () => {
                const res = fr.result
                // console.log(res)
                dispatch(success_fetchedPostFile(res));
            })
        }).catch(error => {
            console.error(error.message);
            dispatch(error_fetchedPostFile(error.message));
        });
    }
}

// export const getFetchReviewPostFileThumbnail = (jwtToken, postId, file) => {
//     console.log("HITTING - getFetchPostContent jwtToken", jwtToken);
//     console.log("HITTING - getFetchPostContent postId", postId);
//     console.log("HITTING - getFetchPostContent file", file);
//     const url = `${GET_FETCH_REVIEW_POST_FILE}&postId=${postId}&file=${file}`
//     const apiUrl = new URL(url);
//     console.log(apiUrl);
//     fetch(apiUrl, {
//         method: "GET",
//         headers: {
//             'Authorization': `Bearer ${jwtToken}`,
//         },
//     }).then(res => {

//         if (res.ok) {
//             return res.blob()
//         } else {
//             throw new Error(res.statusText)
//         }
//     }).then(blob => {
//         // console.log(blob)
//         const file = new File([blob], 'image', { type: 'image/jpeg' });
//         const fr = new FileReader()
//         fr.readAsDataURL(file)
//         fr.addEventListener("load", () => {
//             const res = fr.result
//             // console.log(res)
//             return res
//         })
//     }).catch(error => {
//         throw new Error(error)
//     });

// }



export const getFetchReviewPostFileThumbnail = async (jwtToken, postId, file) => {
    try {
        const url = `${GET_FETCH_REVIEW_POST_FILE}&postId=${postId}&file=${file}`;
        const apiUrl = new URL(url);
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
        });

        if (response.status === 401) {
            //Anauthorized user
            localStorage.clear();
            return null;
        }

        if (response.ok) {
            const blob = await response.blob();
            const file = new File([blob], 'image', { type: 'image/jpeg' });
            const fr = new FileReader();

            return new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = () => {
                    reject(new Error('Failed to read the file.'));
                };
                fr.readAsDataURL(file);
            });
        } else {

            // throw new Error(response.statusText);
            return null
        }
    } catch (error) {
        console.error('Error fetching thumbnail:', error);
        return null;
    }
};
