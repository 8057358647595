
import Dexie from "dexie";

export const db = new Dexie("Nidra");

db.version(2).stores({
    images: "++id",
    videos: "++id",
});

db.open().catch(function (error) {
    console.error("Failed to open database: " + error);
});

export async function clearDatabase() {
    try {
        await db.images.clear();
        await db.videos.clear();
        console.log('Database cleared successfully.');
    } catch (error) {
        console.error('Error clearing database:', error);
    }
}