import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './BlogDetails.scss';
import image from './image.png';
import { blogDetails, recent_blogs } from './BlogDetailsConstantData'

export default function BlogDetails() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { blogId } = useParams();
    const navigate = useNavigate();
    const [blogsLength, setBlogsLength] = useState(blogDetails.length)

    console.log("blogId", blogId, "blogsLength", blogsLength)





    const selectedBlog = blogDetails.find(blog => blog.id == blogId);

    if (!selectedBlog) {
        return <div>Blog not found</div>;
    }

    const handleNextBlog = () => {
        navigate(`/blogdetails/${Number(blogId) + 1}`)
        window.scrollTo(0, 0);
    }

    const handleOnClickBlog = (index) => {
        navigate(`/blogdetails/${index + 1}`)
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <section className='blogDetails'>
                <div className='fluid-container'>
                    <div className='left_container'>
                        <div className='heading'>
                            {selectedBlog?.head && <h2>{selectedBlog?.head}</h2>}
                        </div>

                        <div className='date_box'>
                            <span></span>
                            <p>{selectedBlog?.date}</p>
                            <div className='horizontal-line'></div>
                        </div>
                        <div className='blog_information'>
                            <div className='analysis_points_cont'>
                                {selectedBlog?.name?.map((subEle, subIndex) => {
                                    return (
                                        <div className='analysis_box' key={subIndex}>
                                            {subEle?.type && <h4>{subEle?.type}</h4>}
                                            {subEle?.subType && <h5>{subEle?.subType}</h5>}
                                            {subEle?.description?.map((desc, i) => {
                                                return (
                                                    <div className='analysis_subheading' key={i}>
                                                        <p>{desc?.para}</p>
                                                    </div>
                                                )
                                            })}
                                            {
                                                subEle?.images?.length > 1
                                                    ? (
                                                        <div key="multiple-images" className='multiple_images'>
                                                            {subEle?.images?.map((imgItem, i) => (
                                                                <img key={i} src={imgItem?.img} alt={`Image ${i}`} />
                                                            ))}
                                                        </div>
                                                    )
                                                    : subEle?.images?.map((imgItem, i) => (
                                                        <img key={i} src={imgItem?.img} alt={`Image ${i}`} />
                                                    ))
                                            }
                                            {
                                                subEle?.links?.map((link, i) =>
                                                    <div className='links'>

                                                        <a href={link?.a} target='_blank' className='anchor'>{link?.a}</a>
                                                    </div>

                                                )

                                            }
                                            {
                                                subEle?.lists && <ul className='list'>
                                                    {
                                                        subEle?.lists?.map((li, i) =>
                                                            <li className='list_item' key={i}>{li}</li>

                                                        )
                                                    }
                                                </ul>
                                            }

                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {
                            blogId != blogsLength &&
                            <div className='button_Container'>
                                <button onClick={handleNextBlog}>Next Blog</button>
                            </div>
                        }

                    </div>
                    <div className='right_container'>
                        <h4>RECENT BLOGS</h4>
                        <div className='recent_blog_containers'>
                            {recent_blogs.map((ele, index) => {
                                return (
                                    <>
                                        <div className='recent_blog_box' key={index} onClick={() => handleOnClickBlog(index)}>
                                            <div className='left_recent_blog'>
                                                <h5>{ele.head}</h5>
                                                <p>{ele.description}</p>
                                            </div>
                                            <div className='right_recent_blog'>
                                                <img src={ele.img} alt='Recent blog image' />
                                            </div>
                                        </div >
                                        {
                                            recent_blogs.length !== index + 1 && <div className='horizontal-line'></div>
                                        }

                                    </>
                                )
                            })}
                        </div>
                        <button onClick={() => navigate("/blogs")}>SHOW MORE</button>
                    </div>
                </div>
            </section >
        </div >
    );
}
