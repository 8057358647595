import React, { useState, useRef, useEffect } from 'react';
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./Upload.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp, faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoginDialog } from '../../redux/reducers/loginSlice';
import { setAlertMessage, setAlertType } from '../../redux/reducers/alertSlice';
import axios from 'axios';
import { PUT_CREATE_GAME, PUT_CREATE_GAME_AND_ASSIGN_TO_COACH } from '../../config/endpoints';
import { clearDatabase, db } from '../../db/db';
import { Link, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getCoaches } from "../../redux/network/coachAPI";


const guidelines = [
  { id: "1.", guideline: "Before recording, position your camera 2.5mts from the baseline and 3mts high with a tripod for steady, clear visuals of the court." },
  { id: "2.", guideline: "Ensure good lighting and reduce background noise for better video quality." },
  { id: "3.", guideline: "Record videos (MP4, MOV, or MKV format), keeping them under 200 MB or 10mins duration, and upload here!" },
];

export default function Upload() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const jwtToken = localStorage.getItem("BQS_TOKEN")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // useEffect(() => {
  //   if(jwtToken){
  //     dispatch(getCoaches(jwtToken))
  //   }
  // }, [jwtToken]);





  //checking for jwt token
  useEffect(() => {
    if (!jwtToken) {
      dispatch(setIsLoginDialog(true))
    }
  }, [jwtToken]);

  //coach id 
  // const coachData = useSelector(state => state.coach.coachList)
  // const coachId = coachData?.value[0]?._id

  const [uploading, setUploading] = useState(false); // State to track uploading status
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const [paused, setPaused] = useState(false); // State to track upload pause status
  const [uploadSuccess, setUploadSuccess] = useState(false); // State to track upload success
  const [uploadResponse, setUploadResponse] = useState("");

  const [selectedFile, setSelectedFile] = useState(null); // State to track selected file
  const [timeRemaining, settimeRemaining] = useState('')

  const intervalRef = useRef(); // Ref to hold interval reference
  const startTimeRef = useRef(); // Ref to hold start time
  const elapsedTimeRef = useRef(0); // Ref to hold elapsed time
  const remainingTimeRef = useRef(10); // Ref to hold remaining time (in seconds)

  const cancelTokenSource = useRef(null); // Ref to hold cancel token


  // // Effect to remove success message after 2 seconds
  // useEffect(() => {
  //   let timer;
  //   if (uploadSuccess) {
  //     timer = setTimeout(() => {
  //       setUploadSuccess(false);
  //     }, 5000);
  //   }
  //   return () => clearTimeout(timer);
  // }, [uploadSuccess]);

  const handleOnClickbrowseFile = async () => {
    // await clearDatabase()
    setUploadResponse('')
    // setUploadSuccess(false)
    // setSelectedFile(null)
    setUploadProgress(0)
  }

  const handleFileInput = async (event) => {
    setUploadResponse('')
    setUploadSuccess(false)
    setUploadProgress(0)

    const file = event?.target?.files[0];
    //file size check
    // const maxSize = 250 * 1024 * 1024;
    // if (file?.size > maxSize) {
    //   setUploadResponse("Selected file is too large. Please choose a file less than 250MB.");
    //   return;
    // } else {
    // }
    setSelectedFile(file);
  };

  const handleUpload = async () => {

    if (paused) {
      startTimeRef.current = Date.now() - elapsedTimeRef.current;
    } else {
      startTimeRef.current = Date.now(); // Record start time
      setUploadProgress(0); // Reset progress to 0%
      setUploadSuccess(false); // Remove success message
    }

    //checking for Login status and Coach id
    if (!jwtToken) {
      dispatch(setIsLoginDialog(true))
      dispatch(setAlertType("error"))
      dispatch(setAlertMessage("Please Login to upload video"))
      return;
    }

    if (!selectedFile) {
      // dispatch(setAlertMessage("Please select file to upload"))
      // dispatch(setAlertType("error"))
      setUploadResponse("Please select file to upload")
      return
    }

    setUploading(true);
    setUploadResponse(null);


    // Create a new cancel token source
    cancelTokenSource.current = axios.CancelToken.source();
    const startTime = Date.now(); // Define and initialize startTime
    // console.log("Jwt token", jwtToken);

    // const myHeaders = new Headers();
    // myHeaders.append("filename", selectedFile?.name);
    // myHeaders.append("streamsize", selectedFile?.size);
    // myHeaders.append("Content-Type", "video/mp4");
    // myHeaders.append("Authorization", `Bearer ${jwtToken}`);

    const url = `${PUT_CREATE_GAME}`

    // console.log("video_post_url", url)

    try {
      const response = await axios.put(url, selectedFile, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          filename: selectedFile.name,
          streamsize: selectedFile.size,
        },
        // headers: myHeaders,
        cancelToken: cancelTokenSource.current.token,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          console.log(progress)
          setUploadProgress(progress);

          // Calculate remaining time
          const elapsedTime = Date.now() - startTime;
          const remainingBytes = progressEvent.total - progressEvent.loaded;
          const uploadSpeed = progressEvent.loaded / elapsedTime;
          const remainingTimeInSeconds = remainingBytes / uploadSpeed;
          console.log("Remaining time:", remainingTimeInSeconds);
          // settimeRemaining(Math.round(remainingTimeInSeconds));
          settimeRemaining(Math.round(Math.round(remainingTimeInSeconds / 1000)));
        }
      });

      // Upload successful
      // console.log("Upload successful:", response.data);
      console.log("Upload response:", response);

      if (response.data.code === 200) {
        // localStorage.setItem("reviewPostId", response?.data?.data?._id)
        setUploadResponse("Video uploaded successfully")
        setUploadSuccess(true)
      } else {
        if (response.status !== 200) {
          throw new Error(response?.data?.message || "Something went wrong while uploading video ");
        }
      }
    } catch (error) {
      // Upload failed or cancelled
      if (axios.isCancel(error)) {
        console.log("Upload cancelled:", error?.message);
      } else {
        console.error("Upload failed:", error);
        if (error?.response?.data?.code === 400) {
          setUploadResponse("Video already exists. Please upload a different video");
        } else if (error?.response?.data?.code === 401) {
          //Anauthorized user
          dispatch(setIsLoginDialog(true))
          localStorage.clear()
        }
        else {
          setUploadResponse(error?.response?.data?.message || "Upload failed. Please try again.");
        }
      }
    } finally {
      // Reset uploading state and cancel token
      setUploading(false);
      cancelTokenSource.current = null;
    }

  };

  const handlePause = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Upload paused by user");
      cancelTokenSource.current = null;
    }
  };

  const handleResume = () => {
    handleUpload();
  };

  const handleCancel = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Upload cancelled by user");
      cancelTokenSource.current = null;
    }
    setUploading(false);
    // setSelectedFile(null);
    setUploadProgress(0);
    setUploadResponse("");
  };



  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const maxSize = 250 * 1024 * 1024;
    if (file) {
      if (file.size > maxSize) {
        setUploadResponse("Selected file is too large. Please choose a file less than 250MB.");
        return;
      } else {
        setSelectedFile(file);
      }
    }
    event.target.classList.remove(styles.drag_over);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    // Add visual indication for drag and drop area
    event.target.classList.add(styles.drag_over);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    // Remove visual indication for drag and drop area
    event.target.classList.remove(styles.drag_over);
  };

  const handleNext = () => {
    navigate("/game-posts/game-videos")
  }

  return (

    <div className={styles.container_upload_section}>
      <div className={styles.uploadSection}>
        <h1>Upload your video</h1>
        <div className={styles.Upload}>
          <div className={styles.left_container} onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>
            <div className={styles.browse_container}>
              <svg viewBox="0 0 69 86" fill="#247E45" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.04158 0.5C3.92547 0.5 2.85506 0.947766 2.06584 1.7448C1.27663 2.54183 0.833252 3.62283 0.833252 4.75V72.75C0.833252 76.1315 2.16338 79.3745 4.53103 81.7656C6.89868 84.1567 10.1099 85.5 13.4583 85.5H55.5416C58.8899 85.5 62.1012 84.1567 64.4688 81.7656C66.8364 79.3745 68.1666 76.1315 68.1666 72.75V26C68.1663 24.8729 67.7228 23.7921 66.9335 22.9953L45.8919 1.74525C45.1028 0.948165 44.0326 0.500241 42.9166 0.5H5.04158ZM42.9166 10.7595L58.0077 26H42.9166V10.7595ZM45.0207 57.3055C45.6604 56.9325 46.1916 56.396 46.561 55.7499C46.9303 55.1038 47.1247 54.371 47.1247 53.625C47.1247 52.879 46.9303 52.1462 46.561 51.5001C46.1916 50.854 45.6604 50.3175 45.0207 49.9445L32.3957 42.5835C31.756 42.2105 31.0303 42.0141 30.2916 42.0141C29.5529 42.0141 28.8272 42.2105 28.1875 42.5835C27.5477 42.9565 27.0165 43.493 26.6471 44.139C26.2777 44.7851 26.0833 45.518 26.0832 46.264V60.986C26.0833 61.732 26.2777 62.4649 26.6471 63.111C27.0165 63.757 27.5477 64.2935 28.1875 64.6665C28.8272 65.0395 29.5529 65.2359 30.2916 65.2359C31.0303 65.2359 31.756 65.0395 32.3957 64.6665L45.0207 57.3055Z" fill="#247E45" />
              </svg>
              <h2>Drag & Drop</h2>
              <p className={styles.fileSupportedType}>Files supported: MP4, MOV, MKV</p>
              <p className={styles.fileSupportedType}>Size Limit:200MB</p>
              <p className={styles.browseFile}>
                or {" "}
                <label onClick={handleOnClickbrowseFile}>
                  browse files
                  <input
                    type="file"
                    onChange={handleFileInput}
                    accept=".mov,.mkv,.mp4,video/*"
                    style={{ width: "0px", opacity: 0 }}
                  />
                </label>
                {" "}
                on your computer
              </p>
              <p className={styles.filename}>{selectedFile?.name}</p>
              <p className={`${uploadSuccess ? styles.success_message : styles.error_message}`}>
                {uploadResponse ? uploadResponse : ''}</p>
            </div>
            {uploading && (
              <>
                <div className={styles.progress_container}>
                  <div className={styles.button_group}>
                    {selectedFile.name && <p>{selectedFile.name}</p>}
                    <div>
                      {/* {paused ? (
                    <button onClick={handleResume} disabled={uploadProgress === 100}>
                      <i className="fa-solid fa-play"></i>
                    </button>
                  ) : (
                    <button onClick={handlePause}>
                      <i className="fa-solid fa-pause"></i>
                    </button>
                  )} */}
                      <button onClick={handleCancel} disabled={uploadProgress === 100}>
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </div>
                  <LinearProgress variant="determinate" value={uploadProgress} className={styles.progress_bar} />
                  <div className={styles.progress_info}>
                    <p>{uploadProgress}%</p>
                    {/* <p>{remainingTimeRef.current}s remaining</p> */}
                    <p>{timeRemaining}s remaining</p>
                  </div>
                </div>
              </>
            )}

            <div className={styles.button_container}>

              {
                uploadSuccess && uploadProgress >= 100 ? <button onClick={handleNext}>Next {<ArrowForwardIcon />}</button> : <button onClick={handleUpload} disabled={uploadProgress > 0 ? true : false}>UPLOAD</button>
              }


            </div>

          </div>
          <div className={styles.right_container}>
            <div className={styles.video_box}>
              <video width={100} controls>
                <source src="assets/GuidelinesVideo.mp4" type="video/mp4" />
              </video>
            </div>
            <div className={styles.guidelines_box}>
              <h2>Guidelines to Record and Upload</h2>
              <div className={styles.guidelines_wrp}>
                {guidelines.map((ele) => {
                  return (
                    <div key={ele.id} className={styles.guidelines_list}>
                      <Avatar
                        sx={{
                          width: {
                            xs: "2px",
                            md: "6px",
                            lg: "8px"
                          },
                          height: {
                            xs: "2px",
                            md: "6px",
                            lg: "8px"
                          },
                          padding: {
                            xs: "12px",
                            md: "14px",
                            lg: "16px"
                          },
                          backgroundColor: "#247E45"
                        }}
                      >
                        {ele.id}
                      </Avatar>

                      <p>{ele.guideline}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
