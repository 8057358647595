import React, { useEffect } from 'react'
import image3 from "./blog3Img.png"
import "./Blogs.scss"
import { useNavigate } from 'react-router-dom';

export default function Blogs() {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const blog = [
    {
      head: "Perfect Smash!",
      pera: "The badminton smash is a powerful downward attacking shot designed to end a rally or elicit a weak response from opponents. The world record for a smash is staggering 565 km/hour!...",
      ownerName: "Visist Team",
      date: "30 MAY 2024",
      type: [
        { typeName: "Grip Technique" },
        { typeName: "Positioning" },

      ],
      img: "assets/perfectSmash.jpg",
    },
    {
      head: "The Power of Video Analysis in Sports",
      pera: "Video analysis is a transformative tool for athletes and coaches aiming to enhance performance and strategy. By reviewing game footage, you can spot..",
      ownerName: "Visist Team",
      date: "2 JUNE 2024",
      type: [
        { typeName: "Video Analysis" },
        { typeName: "Benefits" },

      ],
      img: "assets/powerOfVideoAna.png",
    },
    {
      head: "VIDEO ANALYSIS IN PERFORMANCE SPORTS",
      pera: "Video analysis has become an integral tool for sports coaches and athletes to enhance performance. Here are four....",
      ownerName: "Visist Team",
      date: "28 JUNE 2024",
      type: [
        { typeName: "Video Analysis" },
        { typeName: "Benefits" },

      ],
      img: image3,
    },
  ];

  const handleClick = (index) => {
    navigate(`/blogdetails/${index + 1}`);
  }


  return (
    <div>
      <section className="blog">
        <div className="container">
          <div className="heading">
            <h1>Blog</h1>
          </div>
        </div>
      </section>
      <section className='blog_box'>
        <div className='container'>
          <div className="blog_container">
            {blog.map((ele, index) => (
              <div className="box">
                <div className="item" key={index} onClick={() => handleClick(index)}>
                  <img src={ele.img} alt="" />
                  <div className="blg_info">
                    <h5 >{ele.date}</h5>
                    <ul>
                      {ele.type.map((i, idx) => (
                        <li key={idx}>{i.typeName}</li>
                      ))}
                    </ul>
                    <h2>{ele.head}</h2>
                    <p>{ele.pera}</p>
                    <div className="ownerInfo">
                      <h6>Written by</h6>
                      <h3>{ele.ownerName}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
