import { DELETE_GAME_POST, GET_VIDEO_UPLOAD_QUOTA, GET_FETCH_GAME_POST_FILE, GET_FETCH_REVIEW_POST_FILE, GET_POST_CONTENT, GET_VIDEO_STANDALONE, LOGIN_REDIRECT, MARK_FAV_GAME_POST, POST_ALL_POST_COUNT, POST_LIST_POST } from "../../config/endpoints";

import { setIsLoginDialog } from "../reducers/loginSlice";
import { error_GetFetchVideoContent, error_GetVideo, error_GetVideosCount, isStarted_GetFetchVideoContent, isStarted_GetVideo, isStarted_GetVideosCount, success_GetFetchVideoContent, success_GetVideo, success_GetVideosCount } from "../reducers/videoSlice";
const env = process.env.REACT_APP_ENV;

export const getAllPostCounts = (jwtToken, formData) => {
    console.log("HITTING - getAllPostCounts", jwtToken, formData);


    return dispatch => {
        dispatch(isStarted_GetVideosCount());
        let apiUrl;

        if (env === "standalone") {
            // apiUrl = new URL('/game-posts/Video.json');
         } 
        else {
            let urlWithUserId = GET_VIDEO_UPLOAD_QUOTA.replace('{userId}',formData.condition.poster);
            let urlWithUserIdAndState = urlWithUserId.concat(
                                     formData.condition.state === '' ? 'none' : formData.condition.state);
            apiUrl = new URL(urlWithUserIdAndState);
        }

        console.log("url is " , apiUrl);
        fetch(apiUrl, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        }).then(res => {
                console.log("$$$$$$$$$$$$$$$$$$$$$$$$$" , res);
                if (!res.ok) {
                    if (res.status === 401) {
                        // Unauthorized
                        window.location = LOGIN_REDIRECT;
                        localStorage.clear()
                        dispatch(setIsLoginDialog(true))
                    } else {
                        throw new Error(res.statusText || "Something went wrong");
                    }
                }
                return res.json();
            })
            .then(response => {
                console.log("video success response", response)
                if (!response || !response.hasOwnProperty('code') || response.code !== 200) {
                    throw new Error('Server error: ' + response.message);
                }
                dispatch(success_GetVideosCount(response.data));
            })
            .catch(error => {
                console.error(error.message);
                dispatch(error_GetVideosCount(error.message));
            });
    }

}

export const getVideos = (jwtToken, formData) => {
    console.log("HITTING - getVideos", jwtToken, formData);
    return dispatch => {
        dispatch(isStarted_GetVideo());
        let apiUrl
        if (env === "standalone") {
            //  apiUrl = new URL('/Video.json', window.location.origin);
        } else {
             apiUrl = new URL(POST_LIST_POST);
        }

        console.log("apiUrl===================", apiUrl, jwtToken);
        fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(res => {
                console.log(res);
                if (!res.ok) {
                    if (res.status === 401) {
                        // Unauthorized
                        window.location = LOGIN_REDIRECT;
                        localStorage.clear()
                        dispatch(setIsLoginDialog(true))
                    } else {
                        throw new Error(res.statusText || "Something went wrong");
                    }
                }
                return res.json();
            })
            .then(response => {
                console.log("video success response", response)
                if (!response || !response.hasOwnProperty('code') || response.code !== 200) {
                    throw new Error('Server error: ' + response.message);
                }
                dispatch(success_GetVideo(response.data));
            })
            .catch(error => {
                console.error(error.message);
                dispatch(error_GetVideo(error.message));
            });
    }
}

export const getUpdatedVideos = (jwtToken, formData) => {
    console.log("HITTING - getVideos", jwtToken, formData);
    return dispatch => {
        // dispatch(isStarted_GetVideo());
        var apiUrl = new URL(POST_LIST_POST);
        console.log(apiUrl);
        fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(res => {
                console.log(res);
                if (!res.ok) {
                    if (res.status === 401) {
                        // Unauthorized
                        window.location = LOGIN_REDIRECT;
                        localStorage.clear()
                        dispatch(setIsLoginDialog(true))
                    } else {
                        throw new Error(res.statusText || "Something went wrong");
                    }
                }
                return res.json();
            })
            .then(response => {
                console.log("video success response", response)
                if (!response || !response.hasOwnProperty('code') || response.code !== 200) {
                    throw new Error('Server error: ' + response.message);
                }
                dispatch(success_GetVideo(response.data));
            })
            .catch(error => {
                console.error(error.message);
                dispatch(error_GetVideo(error.message));
            });
    }
}

// export const getFetchPostContent = (jwtToken, postId, type) => {
//     console.log("HITTING - getFetchPostContent", jwtToken, postId);
//     return dispatch => {
//         const url = `${GET_POST_CONTENT}?postId=${postId}&type=${type}`
//         const apiUrl = new URL(url);
//         console.log(apiUrl);
//         dispatch(isStarted_GetFetchVideoContent());
//         fetch(apiUrl, {
//             method: "GET",
//             headers: {
//                 'Authorization': `Bearer ${jwtToken}`,
//             },
//         }).then(res => {
//             return res.blob()
//         }).then(blob => {
//             console.log(blob)
//             const file = new File([blob], 'video', { type: 'video/mp4' });
//             const fr = new FileReader()
//             fr.readAsDataURL(file)
//             fr.addEventListener("load", () => {
//                 const res = fr.result
//                 // console.log(res)
//                 dispatch(success_GetFetchVideoContent(res));
//             })
//         }).catch(error => {
//             console.error(error.message);
//             dispatch(error_GetFetchVideoContent(error.message));
//         });
//     }
// }

export function getFetchPostContent(jwtToken, postId) {

    //const url = `${GET_POST_CONTENT}?postId=${postId}&type=game`;
    const url = localStorage.getItem("videoLinkInObjectStore");

    return async dispatch => {
        dispatch(isStarted_GetFetchVideoContent());

        try {
            const response = await fetch(url, {
                method: "GET"
                // ,
                // headers: {
                //     'Authorization': `Bearer ${jwtToken}`,
                // },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    //Anauthorized user
                    window.location = LOGIN_REDIRECT;
                    dispatch(setIsLoginDialog(true))
                    localStorage.clear();
                    return;
                }

                throw new Error('Failed to fetch video');
            }

            const reader = response.body.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                    return pump();
                }
            });

            const responseBlob = await new Response(stream).blob();
            const file = new File([responseBlob], 'video.mp4', { type: 'video/mp4' });
            const fr = new FileReader();

            fr.onload = () => {
                const result = fr.result;
                dispatch(success_GetFetchVideoContent(result));
            };

            fr.onerror = () => {
                throw new Error('Failed to read file');
            };

            fr.readAsDataURL(file);
        } catch (error) {
            dispatch(error_GetFetchVideoContent(error.message || 'Error while fetching video'));
            console.error('Error fetching video:', error);
        }
    };
}

export const getFetchGamePostFileThumbnail = async (jwtToken, postId, file) => {
    try {
        //const url = `${GET_FETCH_GAME_POST_FILE}&postId=${postId}&file=${file}`;

        
        const url = file; // will fetch directly from S3 bucket in AWS , for performance improvement

        const apiUrl = new URL(url);
        const response = await fetch(apiUrl, {
            method: "GET" //,
            // headers: {
            //     'Authorization': `Bearer ${jwtToken}`,
            // },
        });

        if (response.status === 401) {
            //Anauthorized user
            localStorage.clear();
            return null;
        }

        if (response.ok) {
            const blob = await response.blob();
            const file = new File([blob], 'image', { type: 'image/jpeg' });
            const fr = new FileReader();

            return new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = () => {
                    reject(new Error('Failed to read the file.'));
                };
                fr.readAsDataURL(file);
            });
        } else {

            // throw new Error(response.statusText);
            return null
        }
    } catch (error) {
        console.error('Error fetching thumbnail:', error);
        return null;
    }
};

export const deleteGamePost = async (jwtToken, postId) => {
    try {
        const url = `${DELETE_GAME_POST}/?postId=${postId}`;
        const apiUrl = new URL(url);
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
        });

        if (response.status === 401) {
            localStorage.clear();
            return { code: 401, message: "Unauthorized" };
        }

        const result = await response.json();
        return result;

    } catch (error) {
        console.error('deleteGamePost error:', error);
        return { code: 500, message: "Internal Server Error" };
    }
};

export const markGamePostFav = async (jwtToken, postId, favToggle) => {
    try {
        const url = `${MARK_FAV_GAME_POST}${postId}&${favToggle}`;
        const apiUrl = new URL(url);
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
        });

        if (response.status === 401) {
            localStorage.clear();
            return { code: 401, message: "Unauthorized" };
        }

        const result = await response.json();
        return result;

    } catch (error) {
        console.error('markGamePostFav error:', error);
        return { code: 500, message: "Internal Server Error" };
    }
};



// export const getFetchPostContent = (jwtToken, postId, type) => {
//     console.log("HITTING - getFetchPostContent", jwtToken, postId);
//     return dispatch => {
//         const BASE_URL = 'http://stg.borqs.io/assetmgmt/post?postId=';
//         const assetMgmtURL = `${BASE_URL}${postId}&type=${type}`;

//         dispatch(isStarted_GetFetchVideoContent());

//         axios.get(assetMgmtURL, {
//             headers: {
//                 Authorization: `Bearer ${jwtToken}`
//             },
//             responseType: 'stream'
//         }).then(response => {
//             const stream = response.data;
//             stream.ondata = (chunk) => {
//                 console.log('Received chunk:', chunk);
//                 // Here you can handle the received chunk, like writing it to a file
//             };
//             stream.onend = () => {
//                 console.log('Stream ended');
//             };
//             stream.onerror = (error) => {
//                 console.error('Stream error:', error);
//                 dispatch(error_GetFetchVideoContent(error.message));
//             };
//         }).catch(error => {
//             console.error(error.message);
//             dispatch(error_GetFetchVideoContent(error.message));
//         });
//     };
// };


