import React, { useEffect, useState } from 'react';
import './ReportContent.scss';
import imageUrl from './dummy_big.png';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFetchReviewPostFileThumbnail } from '../../../redux/network/reportAPI';
import { getFetchGamePostFileThumbnail, markGamePostFav } from '../../../redux/network/videoApi';
import { setAlertMessage, setAlertType } from '../../../redux/reducers/alertSlice';

export default function ReportContent() {
  const dispatch = useDispatch()

  const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { technique } = useParams();
  const jwtToken = localStorage.getItem('BQS_TOKEN');
  const videoContent = useSelector((state) => state.video.videoContent);
  const reviewedPostData = JSON.parse(localStorage.getItem('reviewPostData'));
  console.log("reviewedPostData", reviewedPostData)
  const reviewPostId = reviewedPostData?._id;
  const reviewPostUpdatedAt = reviewedPostData?.createdAt;
  const { techniques } = useSelector((state) => state.report.postsList);

  // states
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(reviewedPostData.fav);
  const [thumbnail, setThumbnail] = useState(null);

  // console.log("thumbnail======>", thumbnail)

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, reviewedPostData?.game?.tnPath);

        console.log("thumbnailUrl======>", thumbnailUrl)
        setThumbnail(thumbnailUrl);
      } catch (error) {
        console.error('Error fetching thumbnail:', error);
      }
    };

    fetchThumbnail();
  }, [jwtToken, reviewPostId, reviewedPostData?.game?.tnPath]);

  const handleClickVideo = () => {
    setIsPlayVideo(true);
  };

  const getTechniqueIndex = () => {
    return Object.keys(techniques).indexOf(technique);
  };

  const handleNext = () => {
    const isFieldYourApproach = pathname.split('/').includes('your-approach');
    const currentIndex = getTechniqueIndex();
    if (isFieldYourApproach) {
      navigate(`/reports/${reviewPostId}/${technique}/correct-approach`);
      window.scrollTo(0, 0);
    } else {
      if (currentIndex < Object.keys(techniques).length - 1) {
        const nextTechnique = Object.keys(techniques)[currentIndex + 1];
        navigate(`/reports/${reviewPostId}/${nextTechnique}/your-approach`);
        window.scrollTo(0, 0);
      }
    }
  };

  const handleBack = () => {
    navigate('/game-posts/game-videos');
  };

  async function handleClickFav(postData) {

    let favToggle = !postData.fav
    const res = await markGamePostFav(jwtToken, postData._id, favToggle)

    if (res && res.code === 200) {
      // Update the fav property in localStorage
      const updatedPostData = { ...postData, fav: favToggle };
      localStorage.setItem('reviewPostData', JSON.stringify(updatedPostData));

      //Dispatch get list of video
      setIsBookmarked((prev) => !prev)

      console.log("Report view Fav===>", res)

      dispatch(setAlertMessage(res.message || "One post marked Favourite succesffully"))

      dispatch(setAlertType("success"))
    } else {
      dispatch(setAlertMessage(res.message || "Something went wrong"))
      dispatch(setAlertType("error"))
    }
  }


  return (
    <div>
      <section className='reportContent'>
        <div className='content'>
          <div className='cont_head'>
            <div className='rp'>
              <p>{dataFormatter(reviewPostUpdatedAt)}</p>
              <h1>Report</h1>
            </div>
            <div className='mar'>
              <h3>
                <i
                  className={isBookmarked ? 'fa-solid fa-bookmark' : 'fa-regular fa-bookmark'}
                  onClick={() => handleClickFav(reviewedPostData)}
                ></i>{' '}
                Mark
              </h3>
              <h4>
                <span>Reviewed by</span> : Harshit
              </h4>
            </div>
          </div>
          <div className='video_cont'>
            <h5>Uploaded Video</h5>
            {isPlayVideo ? (
              <div className='videoContainer'>
                {videoContent.isStartedvideoContent ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', height: '100%' }}>
                    <CircularProgress />
                    <p style={{ paddingLeft: '10px', color: '#000000' }}>Loading Video ....</p>
                  </Box>
                ) : videoContent.isvideoContentError ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', height: '100%' }}>
                    <p>{videoContent.videoContentError}</p>
                  </Box>
                ) : (
                  <video controls autoPlay playsInline>
                    <source src={videoContent.videoContentUrl} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ) : (
              <div className='imageContainer' style={{ backgroundImage: `url(${thumbnail || imageUrl})` }}>
                <div onClick={handleClickVideo}>
                  <i className='fa-regular fa-circle-play'></i>
                  <span>Play</span>
                </div>
              </div>
            )}
            <div className='button-container'>
              <button onClick={handleBack}>
                Back <i className='fa-solid fa-arrow-left'></i>
              </button>
              <button onClick={handleNext}>
                Next <i className='fa-solid fa-arrow-right'></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
