import { Dialog } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUploadGuidelineVideoDialogOpen } from '../../redux/reducers/loginSlice'
import styles from './UploadGuidelineVideoModel.module.scss'
import VideoPlayer from 'react-video-js-player';

const UploadGuidelineVideoModel = () => {
    const dispatch = useDispatch()
    const open = useSelector(state => state.login.isUploadGuidelineDialogOpen)
    const handleClose = () => {
        dispatch(setUploadGuidelineVideoDialogOpen(false))
    }
    return (
        <Dialog onClose={handleClose} open={open} fullWidth>
            <div className={styles.right_container}>
                <div className={styles.header}>
                    <h2>Guideline video</h2>
                    <button onClick={handleClose}>X</button>
                </div>

                <div className={styles.video_box}>
                    {/* <video controls autoPlay muted>
                        <source src="/assets/GuidelinesVideo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
                    <VideoPlayer src="/assets/GuidelinesVideo.mp4" controls={true} width="520" height="420" autoplay={true}
                        muted={true} />
                </div>
            </div>
        </Dialog>
    )
}

export default UploadGuidelineVideoModel