import React from 'react'
import "./LoadingButton.scss"
import { CircularProgress } from '@mui/material'

const LoadingButton = () => {
    return (
        <div className='LoadingButtonCon'>
            <CircularProgress color="inherit" size={10} /> <span>Please Wait...</span>
        </div>
    )
}

export default LoadingButton