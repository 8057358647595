import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from '../Components/Home/Home';
import Nav from '../Components/Nav/Nav';
import SignupPage from '../Components/Signup/SingupPage';
import Footer from '../Components/footer/Footer';
import Blogs from '../Components/Blogs/Blogs'
import Upload from '../Components/upload/Upload';
import BlogDetails from "../Components/BlogDetails/BlogDetails"
import ChoosePlan from '../Components/ChoosePlan/ChoosePlan';
import Greetings from '../Components/Greetings/Greetings';
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import TermsService from '../Components/TermService/TermsService';
import About from '../Components/About_Us/About';
import ReportOutlet from '../Components/Reports/ReportOutlet/ReportOutlet';
import ReportContent from '../Components/Reports/ReportContent/ReportContent';
import YourApproach from '../Components/Reports/ReportApproach/YourApproach';
import CorrectApproach from '../Components/Reports/ReportApproach/CorrectApproach';
import ProfileOutlet from '../Components/Profile/ProfileOutlet/ProfileOutlet'
import PersonalDetails from '../Components/Profile/PersonalDetails/PersonalDetails'
import Membership from '../Components/Profile/Membership/Membership'
import ProfileReport from '../Components/Profile/ProfileReport/ProfileReport'
import Support from '../Components/Profile/Support/Support'
import GamePostOutlet from '../Components/GamePost/GamePostOutlet/GamePostOutlet';
import GameVideos from '../Components/GamePost/GameVideos/GameVideos';
import GameReports from '../Components/GamePost/GameReports/GameReports';
import GameMembership from '../Components/GamePost/GameMembership/GameMembership';
import GameSupport from '../Components/GamePost/GameSupport/GameSupport';
import GameFeedbackOutlet from '../Components/GameFeedback/GameFeedbackOutlet/GameFeedbackOutlet';
import GameCoachFeedback from '../Components/GameFeedback/GameCoachFeedback/GameCoachFeedback';
import Highlights from '../Components/GameFeedback/Highlights/Highlights';
import Stats from '../Components/GameFeedback/Stats/Stats';
import LandingPage from '../Components/CoachFlow/landingPage';
import Pending from '../Components/CoachFlow/Pending/Pending';
import Reviewd from '../Components/CoachFlow/Reviewd/Reviewd';
import CoachFeedback2 from '../Components/Video.js/CoachFeedback2';
import Feedback from '../Components//Video.js/Feedback';
import Requestfeedback from '../Components/GamePost/RequestFeedback/Requestfeedback';



export default function Routers() {
  const env = process.env.REACT_APP_ENV;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Nav />
      <Routes>
        <Route index element={<Home />} />
        {env === "users" ? (
          <>
            <Route path="blogs" element={<Blogs />} />
            <Route path="blogdetails/:blogId" element={<BlogDetails />} />
            <Route path="upload" element={<Upload />} />
            <Route path="choose_plan" element={<ChoosePlan />} />
            <Route path="signup" element={<SignupPage />} />


            <Route path='game-posts' element={<GamePostOutlet />}>
              <Route index element={<Navigate to="game-videos" replace />} />
              <Route path='game-videos' element={<GameVideos />} />
              <Route path='game-videos/feedback/:postId' element={<CoachFeedback2 />} />
              <Route path='game-videos/request/:postId' element={<Requestfeedback />} />
              <Route path='game-reports' element={<GameReports />} />
              <Route path='game-reports/feedback/:postId' element={<CoachFeedback2 />} />
              <Route path='game-membership' element={<GameMembership />} />
              <Route path='game-supports' element={<GameSupport />} />
              <Route />
            </Route>

            <Route path="profile" element={<ProfileOutlet />}>
              <Route index element={<PersonalDetails />} />
              <Route path='personal_details' element={<PersonalDetails />} />
              <Route path='membership' element={<Membership />} />
              <Route path='report' element={<ProfileReport />} />
              <Route path='support' element={<Support />} />
              <Route />
            </Route>


            {/* Game Feedback Routes */}
            <Route path="game-feedback" element={<GameFeedbackOutlet />}>
              <Route index element={<GameCoachFeedback />} />
              <Route path="coach-feedback" element={<GameCoachFeedback />} />
              <Route path="highlights" element={<Highlights />} />
              <Route path="stats" element={<Stats />} />
            </Route>

            {/* Other Routes */}
            <Route path="greetings" element={<Greetings />} />
            <Route path="privacy_policy" element={<PrivacyPolicy />} />
            <Route path="term_service" element={<TermsService />} />
            <Route path="about" element={<About />} />

            {/* Reports Routes */}
            <Route path="reports/:reviewPostId" element={<ReportOutlet />}>
              <Route index element={<ReportContent />} />
              <Route path="uploaded-video" element={<ReportContent />} />
              <Route path=":technique/your-approach" element={<YourApproach />} />
              <Route path=":technique/correct-approach" element={<CorrectApproach />} />
            </Route>


            {/* Coach Flow Routes */}
            <Route path="coach_flow" element={<LandingPage />}>
              <Route index element={<Navigate to="pending-video" replace />} />
              <Route path="pending-video" element={<Pending />} />
              <Route path="pending-video/feedback/:postId" element={<Feedback />} />
              <Route path="reviewd-video" element={<Reviewd />} />
              <Route path="reviewd-video/feedback/:postId" element={<CoachFeedback2 />} />
            </Route>
          </>
        ) : env === "standalone" ? (
          <>
            <Route path="game-posts" element={<GamePostOutlet />}>
              <Route index element={<Navigate to="game-videos" replace />} />
              <Route path="game-videos" element={<GameVideos />} />
              <Route path='game-videos/feedback/:postId' element={<CoachFeedback2 />} />
              <Route path='game-videos/request/:postId' element={<Requestfeedback />} />
              <Route path="game-reports" element={<GameReports />} />
              <Route path='game-reports/feedback/:postId' element={<CoachFeedback2 />} />
              <Route path="game-membership" element={<GameMembership />} />
              <Route path="game-supports" element={<GameSupport />} />
            </Route>

            {/* Coach FLow routes */}
            <Route path="coach_flow" element={<LandingPage />}>
              <Route index element={<Navigate to="pending-video" replace />} />
              <Route path="pending-video" element={<Pending />} />
              <Route path="pending-video/feedback/:postId" element={<Feedback />} />
              <Route path="reviewd-video" element={<Reviewd />} />
              <Route path="reviewd-video/feedback/:postId" element={<CoachFeedback2 />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}


const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [location.pathname]); // Trigger when pathname changes

  return null; // This component doesn't render anything
};