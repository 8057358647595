import React from 'react'
import styles from './Highlights.module.scss'

const Highlights = () => {
    return (
        <div className={styles.Highlights}>
            <div className={styles.heading}>
                <h5>Video</h5>
            </div>

            <hr />


        </div>
    )
}

export default Highlights