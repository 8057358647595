import React, { useState, useEffect } from 'react';
import { Link, NavLink, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem, Alert, Avatar } from '@mui/material';
import './Nav.scss';
import LoginPage from '../Login/Login';
import { getUserProfile, getUserProfilePic, refreshToken, userLogout } from '../../redux/network/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { setAlertMessage, setAlertType } from '../../redux/reducers/alertSlice';
import { setIsLoginDialog } from '../../redux/reducers/loginSlice';
import logoSvg from './logo.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

export default function Nav() {
  const dropdownStyles = {
    position: "absolute",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    padding: "10px",
    borderRadius: "4px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  };

  const itemStyles = {
    padding: "8px",
    cursor: "pointer",
    borderBottom: "1px solid #ddd",
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { isLoginDialog } = useSelector(state => state.login)
  const [openDialog, setOpenDialog] = useState(false);

  const [isNavHamClick, setIsNavHamClick] = useState(false)

  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const env = process.env.REACT_APP_ENV;
  //Redirection after some 3 sec
  const dispatch = useDispatch()
  const alertData = useSelector(state => state.alert)
  const { alertMessage, alertTime, alertType } = alertData
  // console.log(alertData)

  useEffect(() => {
    setOpenDialog(isLoginDialog)
  }, [isLoginDialog])

  useEffect(() => {
    if (alertMessage && alertType) {
      const redirectTimeout = setTimeout(() => {
        dispatch(setAlertMessage(""));
        dispatch(setAlertType(""));
      }, alertTime);
      return () => clearTimeout(redirectTimeout);
    }
  }, [alertMessage, alertType]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };


  //Logout User
  const handleLogout = (e) => {
    userLogout(jwtToken).then(res => {
      console.log(res)
      return res.json();
    }).then(response => {
      //clear session
      localStorage.clear()

    }).catch(error => {
      //clear session
      localStorage.clear()

    }).finally(() => {
      dispatch(setIsLoginDialog(true))
      setAnchorEl(null);
      //clear session
      localStorage.clear()
      navigate("/");
    })
  }

  const handleLoginClick = (e) => {
    if (jwtToken) {
      handleClick(e)
    } else {
      dispatch(setIsLoginDialog(true))
    }
  };


  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [token, setToken] = useState("");
  const [standaloneData, setStandaloneData] = useState({})

  // Function to generate a local token
  const generateToken = () => {
    const userID = "user123"; // Example user ID (this can be dynamic based on your user data)
    const newToken = btoa(`${Date.now()}-${Math.random()}`);
    
    localStorage.setItem("BQS_TOKEN", newToken); // Save token to localStorage
    localStorage.setItem("BQS__ID", userID); // Save userID to localStorage
    
    setToken(newToken);
  };
  

  // Handle dropdown toggle
  const chooseUsers = () => {
    setDropdownVisible(!dropdownVisible);
    if (!localStorage.getItem("BQS_TOKEN")) {
      generateToken(); // Generate token if it doesn't exist
    }
  };
  
  const selectRole = (role) => {
    const userID = localStorage.getItem("BQS__ID"); // Retrieve userID from localStorage
    const data = {
      userRole: role,
      id: userID, // Use the userID from localStorage
      token: localStorage.getItem("BQS_TOKEN"), // Fetch token from localStorage
    };
    
    console.log("Selected Role Data:", data);
    setStandaloneData(data);
    setSelectedRole(role);
    setDropdownVisible(false); // Close dropdown after selection
  };
  const handleCloseDialog = () => {
    dispatch(setIsLoginDialog(false))
    setOpenDialog(false);
  };

  const handleOnClickLogo = () => {
    navigate("/")
  }


  useEffect(() => {
    
    if (jwtToken && env !== "standalone") {
      dispatch(getUserProfile(jwtToken))
    }
  }, [jwtToken])


  const userData = useSelector((state) => state.user.profile)
  const { value } = userData
  const { profileData } = value
  const firstName = profileData?.name?.firstName;
  const [showMenu, setShowMenu] = useState(false);
  const [colorChange, setColorChange] = useState(false);

  const colorchnages = () => {
    if (window.scrollY >= 60) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  
  window.addEventListener("scroll", colorchnages);

  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 15) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    };

    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const myFunction = () => {
    setShowMenu(!showMenu);
    document.body.classList.toggle("no-scroll");
  };

  const handleMobileMenuNavigation = (path) => {
    navigate(path)
  }

  const userRole = localStorage.getItem('BQS_ROLE');

  console.log(standaloneData)
  return (
    <>
      <nav className="nav">
        <div className={`nav_wrp`}>
          <div className="logo" onClick={handleOnClickLogo}>
            <img src={logoSvg} alt='logo' />
          </div>

          <div className='alertContainer'>
            {alertMessage && alertType && (
              <Alert severity={alertType} sx={{ zIndex: '9999' }}>
                {alertMessage}
              </Alert>
            )}
          </div>

          <div className={`menuContainer`}>
            <div className={`menu mobile_menu`}>
              {env === "standalone" ? (
                <>
                  <ul>
                    {standaloneData.userRole === "Player" && (
                      <li >
                        <NavLink to="/game-posts">My Videos</NavLink>
                      </li>
                    )}
                    {standaloneData.userRole == "Coach" && (
                      <li >
                        <NavLink to="/coach_flow">My Reviews</NavLink>
                      </li>
                    )}
                  </ul>
                  <div onClick={chooseUsers}>
                    {
                      selectedRole ? <button className="profile" >
                        <svg
                          viewBox="0 0 53 53"
                          fill='#fff'
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.61799 43.2478L10.1688 41.9365C12.5603 40.2146 15.0982 38.8538 17.7823 37.854C20.4646 36.8524 23.3701 36.3516 26.4988 36.3516C29.6256 36.3516 32.5311 36.8524 35.2153 37.854C37.8975 38.8538 40.4354 40.2137 42.8288 41.9337L44.3407 43.2918C48.4698 38.9064 51 32.9987 51 26.5C51 12.969 40.031 2 26.5 2C12.969 2 2 12.969 2 26.5C2 32.977 4.51336 38.8669 8.61799 43.2478ZM53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM20.5003 25.4666C22.1303 27.0948 24.1298 27.9088 26.4988 27.9088C28.8679 27.9088 30.8674 27.0948 32.4974 25.4666C34.1255 23.8366 34.9396 21.8371 34.9396 19.4681C34.9396 17.0991 34.1255 15.0995 32.4974 13.4695C30.8674 11.8414 28.8679 11.0273 26.4988 11.0273C24.1298 11.0273 22.1303 11.8414 20.5003 13.4695C18.8722 15.0995 18.0581 17.0991 18.0581 19.4681C18.0581 21.8371 18.8722 23.8366 20.5003 25.4666Z"
                            fill='#fff'
                          />
                        </svg>
                      </button> :
                        <LightTooltip title="Login" placement="bottom-end">
                          <button className="profile" >
                            <svg
                              viewBox="0 0 53 53"
                              fill='#fff'
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.61799 43.2478L10.1688 41.9365C12.5603 40.2146 15.0982 38.8538 17.7823 37.854C20.4646 36.8524 23.3701 36.3516 26.4988 36.3516C29.6256 36.3516 32.5311 36.8524 35.2153 37.854C37.8975 38.8538 40.4354 40.2137 42.8288 41.9337L44.3407 43.2918C48.4698 38.9064 51 32.9987 51 26.5C51 12.969 40.031 2 26.5 2C12.969 2 2 12.969 2 26.5C2 32.977 4.51336 38.8669 8.61799 43.2478ZM53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM20.5003 25.4666C22.1303 27.0948 24.1298 27.9088 26.4988 27.9088C28.8679 27.9088 30.8674 27.0948 32.4974 25.4666C34.1255 23.8366 34.9396 21.8371 34.9396 19.4681C34.9396 17.0991 34.1255 15.0995 32.4974 13.4695C30.8674 11.8414 28.8679 11.0273 26.4988 11.0273C24.1298 11.0273 22.1303 11.8414 20.5003 13.4695C18.8722 15.0995 18.0581 17.0991 18.0581 19.4681C18.0581 21.8371 18.8722 23.8366 20.5003 25.4666Z"
                                fill='#fff'
                              />
                            </svg>
                          </button>
                        </LightTooltip>
                    }

                    {
                      (selectedRole ? <span>{selectedRole}</span> : null)
                    }
                  </div>

                  {/* Dropdown menu */}
                  {dropdownVisible && (
                    <div className="dropdown" style={dropdownStyles}>
                      <div onClick={() => selectRole("Coach")} style={itemStyles}>
                        Coach
                      </div>
                      <div onClick={() => selectRole("Player")} style={itemStyles}>
                        Player
                      </div>
                    </div>
                  )}
                </>
              ) :
                <>
                  <ul>
                    {/* <li onClick={() => handleMobileMenuNavigation("/")}><NavLink to="/">Home</NavLink></li> */}
                    {/* <li onClick={() => handleMobileMenuNavigation("/upload")}><NavLink to="/upload">Upload</NavLink></li> */}
                    {/* <li onClick={() => handleMobileMenuNavigation("/game-posts/game-videos")}><NavLink to="/game-posts/game-videos">My Journal</NavLink></li> */}

                    {userRole === "player" && (
                      <>
                        <li >
                          <NavLink to="/game-posts">My Journal</NavLink>
                        </li>
                      </>
                    )}
                    {userRole === "coach" && (
                      <>
                        <li>
                          <NavLink to="/coach_flow">My Reviews</NavLink>
                        </li>
                      </>
                    )}


                  </ul>
                  <div onClick={(e) => handleLoginClick(e)} >

                    {
                      jwtToken ? <button className="profile" >
                        <svg
                          viewBox="0 0 53 53"
                          fill='#fff'
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.61799 43.2478L10.1688 41.9365C12.5603 40.2146 15.0982 38.8538 17.7823 37.854C20.4646 36.8524 23.3701 36.3516 26.4988 36.3516C29.6256 36.3516 32.5311 36.8524 35.2153 37.854C37.8975 38.8538 40.4354 40.2137 42.8288 41.9337L44.3407 43.2918C48.4698 38.9064 51 32.9987 51 26.5C51 12.969 40.031 2 26.5 2C12.969 2 2 12.969 2 26.5C2 32.977 4.51336 38.8669 8.61799 43.2478ZM53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM20.5003 25.4666C22.1303 27.0948 24.1298 27.9088 26.4988 27.9088C28.8679 27.9088 30.8674 27.0948 32.4974 25.4666C34.1255 23.8366 34.9396 21.8371 34.9396 19.4681C34.9396 17.0991 34.1255 15.0995 32.4974 13.4695C30.8674 11.8414 28.8679 11.0273 26.4988 11.0273C24.1298 11.0273 22.1303 11.8414 20.5003 13.4695C18.8722 15.0995 18.0581 17.0991 18.0581 19.4681C18.0581 21.8371 18.8722 23.8366 20.5003 25.4666Z"
                            fill='#fff'
                          />
                        </svg>
                      </button> :
                        <LightTooltip title="Login" placement="bottom-end">
                          <button className="profile" >
                            <svg
                              viewBox="0 0 53 53"
                              fill='#fff'
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.61799 43.2478L10.1688 41.9365C12.5603 40.2146 15.0982 38.8538 17.7823 37.854C20.4646 36.8524 23.3701 36.3516 26.4988 36.3516C29.6256 36.3516 32.5311 36.8524 35.2153 37.854C37.8975 38.8538 40.4354 40.2137 42.8288 41.9337L44.3407 43.2918C48.4698 38.9064 51 32.9987 51 26.5C51 12.969 40.031 2 26.5 2C12.969 2 2 12.969 2 26.5C2 32.977 4.51336 38.8669 8.61799 43.2478ZM53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM20.5003 25.4666C22.1303 27.0948 24.1298 27.9088 26.4988 27.9088C28.8679 27.9088 30.8674 27.0948 32.4974 25.4666C34.1255 23.8366 34.9396 21.8371 34.9396 19.4681C34.9396 17.0991 34.1255 15.0995 32.4974 13.4695C30.8674 11.8414 28.8679 11.0273 26.4988 11.0273C24.1298 11.0273 22.1303 11.8414 20.5003 13.4695C18.8722 15.0995 18.0581 17.0991 18.0581 19.4681C18.0581 21.8371 18.8722 23.8366 20.5003 25.4666Z"
                                fill='#fff'
                              />
                            </svg>
                          </button>
                        </LightTooltip>
                    }

                    {
                      (firstName && jwtToken ? <span>{firstName}</span> : null)
                    }
                  </div>
                </>
              }
            </div>
            <div onClick={() => setIsNavHamClick((prev) => !prev)} className='icons-cont'>
              {
                isNavHamClick ? <i className="fa-solid fa-xmark bar-icon"></i> : <i className="fa-solid fa-bars bar-icon" ></i>
              }
            </div>
          </div>
        </div>

      </nav >

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className='logout_menu'
      >

        {userRole === "player" &&
          <MenuItem onClick={handleClose}><Link to="profile/personal_details" style={{ textDecoration: "none", color: "black", }}><svg width="26px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg> <span style={{ fontSize: "18px" }}>Edit Profile</span></Link></MenuItem>
        }

        <MenuItem onClick={handleLogout}><Link style={{ textDecoration: "none", color: "black", }}> <svg width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M10 12H20M20 12L17 9M20 12L17 15" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg><span style={{ fontSize: "18px" }}>Log Out</span></Link></MenuItem>
      </Menu>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ textAlign: "end" }}>
          <Button onClick={handleCloseDialog} sx={{
            fontSize: {
              xs: '14px',
              sm: '18px',
              md: '20px',
              lg: '24px',
            },
            color: "#000000"
          }}><i className="fa-solid fa-xmark" ></i></Button>
        </DialogTitle>
        <DialogContent>
          <LoginPage onCancelDialog={handleCloseDialog} />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>

      {/* MObile view nav */}

      <header
        className={`mobile_nav ${colorChange ? "colorChange" : ""
          }`}
      >
        <nav>
          <div className="top_nav">
            <div className="container">
              <div onClick={(e) => handleLoginClick(e)} className='pro_cont'>
                <button className="profile" >
                  <svg
                    viewBox="0 0 53 53"
                    fill='#000'
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.61799 43.2478L10.1688 41.9365C12.5603 40.2146 15.0982 38.8538 17.7823 37.854C20.4646 36.8524 23.3701 36.3516 26.4988 36.3516C29.6256 36.3516 32.5311 36.8524 35.2153 37.854C37.8975 38.8538 40.4354 40.2137 42.8288 41.9337L44.3407 43.2918C48.4698 38.9064 51 32.9987 51 26.5C51 12.969 40.031 2 26.5 2C12.969 2 2 12.969 2 26.5C2 32.977 4.51336 38.8669 8.61799 43.2478ZM53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM20.5003 25.4666C22.1303 27.0948 24.1298 27.9088 26.4988 27.9088C28.8679 27.9088 30.8674 27.0948 32.4974 25.4666C34.1255 23.8366 34.9396 21.8371 34.9396 19.4681C34.9396 17.0991 34.1255 15.0995 32.4974 13.4695C30.8674 11.8414 28.8679 11.0273 26.4988 11.0273C24.1298 11.0273 22.1303 11.8414 20.5003 13.4695C18.8722 15.0995 18.0581 17.0991 18.0581 19.4681C18.0581 21.8371 18.8722 23.8366 20.5003 25.4666Z"
                      fill='#000'
                    />
                  </svg>
                </button>
                {
                  (firstName && jwtToken ? <span>{firstName}</span> : <span>Login</span>)
                }
              </div>
            </div>
          </div>
          <div className="container">
            <div className="nav_bar">
              <div className="logo">
                <Link to="/">
                  <img src={logoSvg} />
                </Link>
              </div>
              <div className="toggle_button">
                <button
                  onClick={myFunction}
                  className={showMenu ? "menuicons" : ""}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className={`toggle_menu ${showMenu ? "actives" : ""}`}>
        <div className="menus">
          <ul className="navbar_nav">
            {/* <li><NavLink onClick={myFunction} to="/">Home</NavLink></li> */}
            {/* <li><NavLink onClick={myFunction} to="/upload">Upload</NavLink></li> */}
            {/* <li><NavLink onClick={myFunction} to="/game-posts/game-videos">My Journal</NavLink></li> */}
            {userRole === "player" && (
              <>
                <li >
                  <NavLink onClick={myFunction} to="/game-posts">My Journal</NavLink>
                </li>
              </>
            )}
            {userRole === "coach" && (
              <>
                <li>
                  <NavLink to="/coach_flow">My Reviews</NavLink>
                </li>
              </>
            )}

            {
              jwtToken && userRole === "player" && <MenuItem onClick={handleClose}><Link to="profile/personal_details" className="menuItemText" onClick={myFunction}><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg> <span >Edit Profile</span></Link></MenuItem>
            }

            {
              jwtToken && <MenuItem onClick={handleLogout}><Link className="menuItemText" onClick={myFunction}> <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M10 12H20M20 12L17 9M20 12L17 15" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg><span>Log Out</span></Link></MenuItem>
            }

          </ul>

        </div>
      </div>
    </ >
  );
}
