import React, { useState } from 'react';
import '../GameMembership/GameMembership.scss';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    maxWidth: '1000px', // Default max width
    width: '100%',
    marginLeft: '170px',
    marginTop: '70px',
    // overflow: 'hidden',
    scrollbarWidth: 'none', // For Firefox
    '&::-webkit-scrollbar': {
      display: 'none', // For Chrome, Safari, and Edge
    },
    // Add media queries for responsiveness
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1000px',
      marginLeft: '170px',
      marginTop: '50px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginLeft: '5%',
      marginTop: '20%',
     
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
      marginLeft: '10%',
      marginTop: '20px',
    }
    ,
    // Specific styles for very small devices
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%', // Fit the screen width
      marginLeft: '0px',
      marginTop: '10px',
      padding: '8px', // Optional: Reduce padding
    },
  },
}));


const GameMembership = () => {
  const [contactDailog, setContactDailog] = useState(false);
  const handleContactClose = () => {
    setContactDailog(false);
  }
  const [payDailog, setPayDailog] = useState(false);
  const handlePayClose = () => {
    setPayDailog(false);
  }
  const [activeDialog, setActiveDialog] = React.useState(null); // Track which sub-dialog is open

  const handleClickOpen = () => {
    setActiveDialog("athlete"); // Set the active dialog to "athlete"
  };

  const handleOpenDialog = (dialogType) => {
    setActiveDialog(dialogType); // Open specific sub-dialog
  };
  const handleCloseSubDialog = () => {
    setActiveDialog(null); // Close the sub-dialog
  };

  const features = {
    "freetrial": {
      1: "Free Access to Your Journal Videos",
      2: "10GB Space",
      3: "Limited Feedback Reports"
    },
    "champion": {
      1: "Free Access to Your Journal Videos & Video Reports",
      2: "25GB Space",
      3: "4 Feedback Reports/Month",
      4: "Historical Trends",
      5: "Access to Advance Features"
    },
    "academy": {
      1: "Flexible Plans for Academies",
      2: "Custom Dashboards and Reports",
      3: "Assessment Reports"
    }
  };
  const plans = [
    "Upgrade My Plan",
    "Renew My Plan",
    "Contact Sales"
  ]
  const duration = [
    "Free Trial",
    "Champion Plan",
    "Academy Plan"
  ]

  return (
    <div id="membership-outer">

      <div className='filters'>
        <h1>Your Current Plan : Free Trial</h1>
      </div>
      <div id='athlet'>
        <fieldset className="box-2">
          <span>
            <span className="plan">{duration[0]}</span>
          </span>
          <hr className="light-line" />
          <div className="features">
            <p><b>Features</b></p>
            <ul>
              {Object.values(features.freetrial).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <div id="but-content">
              <button className='button' onClick={() => setPayDailog(true)} style={{ backgroundColor: "#E0E1E6", color: "#000000", cursor:"default" }} disabled>
                <span>{plans[0]}</span>
              </button>
            </div>
            <BootstrapDialog
              open={payDailog}
              aria-labelledby="athlete-dialog-title"
              onClose={handlePayClose}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                Champion Plan
                <DialogActions>
                  <Button
                    onClick={handlePayClose}
                    style={{ color: 'black', fontWeight: '900', fontSize: '20px' }}
                  >
                    <CloseIcon />
                  </Button>
                </DialogActions>
              </div>
              <DialogContent>
                <div className="payment">
                  <input type='radio' id='payment-1' name='payment' value='1999' />
                  <label for='payment-1'>1999</label><br></br>
                </div>
                <div className="payment">
                  <input type='radio' id='payment-2' name='payment' value='7499' />
                  <label for='payment-2'>7499</label>
                </div>

                <button className='button-payment'>
                  <span>Pay Now </span>
                </button>

              </DialogContent>
            </BootstrapDialog>
          </div>
        </fieldset>

        <fieldset className="box-2">
          <span>
            <span className="plan">{duration[1]}</span>
          </span>
          <hr className="light-line" />
          <div className="features">
            <p><b>Features</b></p>
            <ul>
              {Object.values(features.champion).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <div id="but-content">
              <button className='button'  onClick={() => setPayDailog(true)} style={{ backgroundColor: "#E0E1E6", color: "#000000",cursor:"default"  }} disabled>
                <span>{plans[1]}</span>
              </button>
            </div>

          </div>
        </fieldset>

        <fieldset className="box-2">
          <span>
            <span className="plan">{duration[2]}</span>
          </span>
          <hr className="light-line" />
          <div className="features">
            <p><b>Features</b></p>
            <ul>
              {Object.values(features.academy).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <div id="but-content">
              <button className='button' onClick={() => setContactDailog(true)}>
                <span>Contact Sales</span>
              </button>
            </div>
            <BootstrapDialog  open={contactDailog} aria-labelledby="athlete-dialog-title" onClose={handleContactClose}>
              <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <DialogTitle style={{ justifyContent: 'space-between'}}  >Contact Us</DialogTitle>
                <DialogActions>
                  <Button onClick={handleContactClose} style={{ color: 'black', fontWeight: '900', fontSize: '20px' }}>
                    <CloseIcon />
                  </Button>
                </DialogActions>
              </div>

              <DialogContent>
                <p style={{ fontSize: '16px', fontWeight: '500' }}>For purchasing this plan, please contact us : <a href="mailto:sales@visist.ai">sales@visist.ai</a></p>
              </DialogContent>

            </BootstrapDialog>
          </div>
        </fieldset>

      </div>
    </div>
  );
};

export default GameMembership;