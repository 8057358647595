export const SNACKBAR_TIMEOUT = 5;  //seconds
export const LOGIN_REDIRECT = `/`;
// export const BORQS_URL = "http://stg.borqs.io";
// export const BORQS_URL = "http://api.visist.ai";
export const BORQS_URL = "https://api-prod.visist.ai";
//export const BORQS_URL = "https://3.108.19.186";
export const POST_REGISTRATION_OTP = `${BORQS_URL}/assetmgmt/user/otp`;
export const POST_REGISTER = `${BORQS_URL}/assetmgmt/user/register`;
export const POST_REGISTER_LOGIN = `${BORQS_URL}/assetmgmt/user/register-login`;
export const POST_LOGIN_OTP = `${BORQS_URL}/assetmgmt/user/otp`;
export const POST_LOGIN_OTP_VERIFY = `${BORQS_URL}/assetmgmt/user/login`;
export const POST_CALENDAR_TEMPLATE_EP = `${BORQS_URL}/assetmgmt/user/logout`;
export const POST_REFRESH_TOKEN = `${BORQS_URL}/assetmgmt/user/token/refresh`;
export const GET_USER_INFO = `${BORQS_URL}/assetmgmt/user`;
export const PUT_UPDATE_USER_INFO = `${BORQS_URL}/assetmgmt/user`;
export const POST_USER_LOGOUT = `${BORQS_URL}/assetmgmt/user/logout`;
export const POST_UPDATE_USER_PIC = `${BORQS_URL}/assetmgmt/user/profile-pic`;
export const GET_USER_PIC = `${BORQS_URL}/assetmgmt/user/profile-pic`;
export const PUT_CREATE_GAME = `${BORQS_URL}/assetmgmt/post/?type=game`;
export const PUT_CREATE_GAME_AND_ASSIGN_TO_COACH = `${BORQS_URL}/assetmgmt/post/?type=game&state=review_requested`;
export const PUT_CREATE_REVIEW_POST = `${BORQS_URL}/assetmgmt/post/?type=review`;
export const GET_FETCH_GAME_POST_FILE = `${BORQS_URL}/assetmgmt/post/?type=game`;
export const DELETE_GAME_POST = `${BORQS_URL}/assetmgmt/post`;
export const MARK_FAV_GAME_POST = `${BORQS_URL}/assetmgmt/post/fav/`;
export const POST_LIST_ALL_COACHES = `${BORQS_URL}/assetmgmt/user/search`;
export const POST_UPDATE_REVIEW_SELECTION = `${BORQS_URL}/assetmgmt/post`; //NEED TO ADD ?postId=662d04c9089ef49fcb4b39e0
export const GET_FETCH_REVIEW_POST = `${BORQS_URL}/assetmgmt/post/?type=review&postId=`;
export const GET_FETCH_REVIEW_POST_FILE = `${BORQS_URL}/assetmgmt/post/?type=review`;
export const GET_ALL_POST_COPY = `${BORQS_URL}/assetmgmt/post/`;
export const GET_LIST_OF_POST_USER = `${BORQS_URL}/assetmgmt/post/qwerty`;
export const GET_A_POST = `${BORQS_URL}/assetmgmt/post/?postId=`
export const GET_POST_CONTENT = `${BORQS_URL}/assetmgmt/post` //NEED TO ADD ?postId=662d04c9089ef49fcb4b39e0
export const POST_ALL_POST_COUNT = `${BORQS_URL}/assetmgmt/post/searchCount`// NEED TO PASS BODY
export const GET_VIDEO_UPLOAD_QUOTA = `${BORQS_URL}/assetmgmt/user/{userId}/video-upload-quota?state=`// NEED TO PASS BODY 
export const POST_LIST_POST = `${BORQS_URL}/assetmgmt/post/search`// NEED TO PASS BODY 
export const POST_CALENDAR_CSV_EP = `${BORQS_URL}/data/configure/calendar`;
export const GET_STORE_MASTER_EP = `${BORQS_URL}/admin/store/master`;
export const BUY_CREDIT = `${BORQS_URL}/assetmgmt/user/buy-credit`;
export const GET_TESTIMONIALS = `${BORQS_URL}/assetmgmt/testimonial/search`;
export const POST_FEEDBACK_URL = `${BORQS_URL}/assetmgmt/post`; // NEED TO ADD /{postId}/feedbacks;
export const CHANGE_POST_STATUS = `${BORQS_URL}/assetmgmt/post` // NEED TO ADD /{postId}/state;

// standalone url

